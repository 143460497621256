import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { PATHS } from 'utils/paths'
import storageService from 'services/StorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { isNotNil } from 'helpers/ramda'
import { pathOr } from 'ramda'

const PrivateRoute = ({ component: Component, webOnly, ...rest }) => {
  const userToken = storageService.get(LOCAL_STORAGE_KEYS.token)
  const isMobile = pathOr(0, ['screen', 'width'], window) <= 768

  return isNotNil(userToken)
    ? isMobile && webOnly
      ? <Redirect to={PATHS.dashboard} />
      : <Route {...rest} render={props => <Component {...props} />} />
    : <Redirect to={PATHS.login} />
}

export default PrivateRoute
