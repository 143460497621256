import React from 'react'
import GoogleLoginButton from 'features/auth/components/GoogleLoginButton'
import styled from 'styled-components'
import Paper from 'components/atoms/Paper'
import LogoImg from 'assets/images/logo-black.svg'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { useSelector } from 'react-redux'
import { PATHS } from 'utils/paths'
import { Redirect } from 'react-router-dom'
import { isEmpty } from 'ramda'

const Login = () => {
  const currentUser = useSelector(getCurrentUser)

  return isEmpty(currentUser)
    ? (
      <LoginPageWrapper>
        <LoginPageContent>
          <Paper>
            <DesmartLogo src={LogoImg} alt='DeSmart logo' />
            <AppTitle>I'mOut</AppTitle>
            <GoogleLoginButton />
          </Paper>
        </LoginPageContent>
      </LoginPageWrapper>
    )
    : <Redirect to={PATHS.dashboard} />
}

export default Login

const LoginPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 80px);
`

const LoginPageContent = styled.div`
  width: 400px;
  margin-bottom: 50px;
  
  & > div {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

const DesmartLogo = styled.img`
  max-width: 200px;
`

const AppTitle = styled.div`
  font-size: 16px;
  margin: 7px 0 30px;
`
