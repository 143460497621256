import React, { useState } from 'react'
import Input from 'components/atoms/Input'
import styled from 'styled-components'
import { Button } from 'de-suite-me-ui'
import { editEmployeeNoteRoutine } from 'features/auth/ducks/actions'
import { useDispatch } from 'react-redux'
import { isNotNilOrEmpty } from 'helpers/ramda'

const EditNoteModal = ({ note, employeeId, closeModal }) => {
  const [value, setValue] = useState(note.note)
  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(editEmployeeNoteRoutine({
      note: value,
      id: note.id,
      employeeId
    }))
    typeof closeModal === 'function' && closeModal()
  }

  return (
    <>
      <Input
        name='note'
        value={value}
        onChange={(_, val) => setValue(val)}
      >
        {note.note}
      </Input>
      <ButtonWrapper>
        <Button
          color='secondary'
          onClick={handleSubmit}
          disabled={!isNotNilOrEmpty(value)}
        >
          Save note
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default EditNoteModal

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`
