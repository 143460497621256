import { createSelector } from 'reselect'
import { propOr, pipe, any, equals, values } from 'ramda'

const getLoaderState = state => state.loader

export const selectGlobalLoader = createSelector(
  getLoaderState,
  pipe(values, any(equals(true)))
)

export const selectLoginLoader = createSelector(
  getLoaderState,
  propOr(false, 'isLoggingIn')
)

export const selectIsAddingAbsence = createSelector(
  getLoaderState,
  propOr(false, 'isAddingAbsence')
)

export const selectIsUpdatingUser = createSelector(
  getLoaderState,
  propOr(false, 'isUpdatingUser')
)
