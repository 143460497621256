import moment from 'moment'

export const implementScrollableContainers = () => {
  const f = document.getElementsByClassName('container')[0]
  const s = document.getElementsByClassName('container')[1]

  let isFirstScrolling = false
  let isSecondScrolling = false

  f.addEventListener('scroll', e => {
    if (!isSecondScrolling) {
      isFirstScrolling = true
      customDebounce('first')
      s.scrollLeft = e.target.scrollLeft
    }
  })

  s.addEventListener('scroll', e => {
    if (!isFirstScrolling) {
      isSecondScrolling = true
      customDebounce('second')
      f.scrollLeft = e.target.scrollLeft
    }
  })

  let timeOut

  const customDebounce = tracker => {
    clearTimeout(timeOut)
    timeOut = setTimeout(() => {
      if (tracker === 'first') {
        isFirstScrolling = !isFirstScrolling
      } else {
        isSecondScrolling = !isSecondScrolling
      }
    }, 700)
  }
}

export const getMonthsInRange = (dateFrom, dateTo) => {
  let monthFrom = Number(moment(dateFrom).format('MM'))
  let yearFrom = Number(moment(dateFrom).year())
  let monthTo = Number(moment(dateTo).format('MM'))
  let yearTo = Number(moment(dateTo).year())

  if (dateFrom <= dateTo) {
    const monthCount = (yearTo - yearFrom) * 12 + (monthTo - monthFrom) + 1

    const months = Array(monthCount)
      .fill()
      .map((_, index) => {
        const year = yearFrom + Math.floor((monthFrom + index - 1) / 12)
        const month =
          monthFrom + index > 12 ? (monthFrom + index) % 12 : monthFrom + index
        return { month, year }
      })

    return months
  } else return []
}
