import React from 'react'
import styled, { css } from 'styled-components'
import { Select, withStyles } from '@material-ui/core'

const AbsenceFormSelect = ({ name, value, options, onChange }) => {
  const StyledSelect = withStyles({
    select: {
      backgroundColor: 'transparent',
      padding: '0 15px',
      color: '#788995',
      fontWeight: '300',
      '&:focus': {
        backgroundColor: 'transparent'
      },
      '&:active': {
        backgroundColor: 'transparent'
      }
    }
  })(Select)

  return (
    <StyledSelect
      value={value}
      onChange={e => onChange(name, e.target.value)}
      disableUnderline
      MenuProps={{
        disableScrollLock: true
      }}
      inputProps={{
        defaultValue: ''
      }}
    >
      <StyledOption
        value='placeholder'
        disabled
      >
        Absence type...
      </StyledOption>
      {
        options && options.map(option => (
          <StyledOption
            key={option.label}
            value={option.value}
          >
            {option.label}
          </StyledOption>
        ))
      }
    </StyledSelect>
  )
}

export default AbsenceFormSelect

const StyledOption = styled.option`
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 5px 25px;
  cursor: pointer;
  
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};  
    color: ${({ theme }) => theme.palette.common.white};
  }
  
  ${({ disabled }) => disabled && css`
    display: none;
  `}
`
