import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import history from 'utils/history'
import { pathOr } from 'ramda'
import { PATHS } from 'utils/paths'
import { redirect } from 'utils/router'
import { useDispatch } from 'react-redux'
import { logoutUserRoutine } from 'features/auth/ducks/actions'

const MobileNavigation = () => {
  const [activeTab, setActiveTab] = useState(PATHS.dashboard)
  const dispatch = useDispatch()

  const getActiveTab = () => {
    const pathName = pathOr('', ['location', 'pathname'], history)
    setActiveTab(pathName)
  }

  history.listen(getActiveTab)

  const handleLogoutUser = () => {
    dispatch(logoutUserRoutine())
  }

  return (
    <MobileNavigationWrapper>
      <Button
        onClick={() => redirect(PATHS.dashboard)}
        active={activeTab === PATHS.dashboard}
      >
        <DashboardOutlinedIcon />
      </Button>
      <Button
        onClick={() => redirect(PATHS.userPanel)}
        active={activeTab === PATHS.userPanel}
      >
        <EventOutlinedIcon />
      </Button>
      <Button onClick={handleLogoutUser}>
        <PowerSettingsNewIcon />
      </Button>
    </MobileNavigationWrapper>
  )
}

export default MobileNavigation

const MobileNavigationWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: ${({ theme }) => theme.palette.common.white};
  display: flex;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, .05);
  z-index: 99;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: ${({ theme }) => theme.palette.common.dustyGray};
  
  &:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.palette.common.alto};
  }
  
  ${({ active }) => active && css`
    color: ${({ theme }) => theme.palette.primary.main};
  `}
  
  svg {
    font-size: 25px !important;
  }
`
