import { createRoutine } from 'redux-saga-routines'
import { takeLatest, call, fork, put } from 'redux-saga/effects'
import * as userService from 'services/UserService'
import storageService from 'services/StorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { pathOr } from 'ramda'

export const loginUserRoutine = createRoutine('LOGIN_USER')
export const logoutUserRoutine = createRoutine('LOGOUT_USER')

function * loginUser ({ payload }) {
  yield put(loginUserRoutine.request())
  try {
    const result = yield call(userService.loginUser, payload)
    storageService.set(LOCAL_STORAGE_KEYS.token, pathOr(null, ['headers', 'authorization'], result))
    storageService.set(LOCAL_STORAGE_KEYS.currentUserId, pathOr(null, ['data', 'data', 'id'], result))
    storageService.set(LOCAL_STORAGE_KEYS.isAdmin, pathOr(null, ['data', 'data', 'is_admin'], result))
    yield put(loginUserRoutine.success(pathOr({}, ['data', 'data'], result)))
  } catch (e) {
    yield put(loginUserRoutine.failure(e))
    console.error(e)
  }
}

function * logoutUser () {
  yield put(logoutUserRoutine.request())
  try {
    const result = yield call(userService.logoutUser)
    storageService.remove(LOCAL_STORAGE_KEYS.token)
    storageService.remove(LOCAL_STORAGE_KEYS.currentUserId)
    yield put(logoutUserRoutine.success(result))
    document.location.reload()
  } catch (e) {
    console.error(e)
  }
}

const initialState = {
  loggingIn: false,
  id: storageService.get(LOCAL_STORAGE_KEYS.currentUserId),
  isAdmin: storageService.get(LOCAL_STORAGE_KEYS.isAdmin)
}

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginUserRoutine.REQUEST:
      return {
        ...state,
        loggingIn: true
      }
    case loginUserRoutine.SUCCESS:
      return {
        ...state,
        isAdmin: action.payload.is_admin,
        id: action.payload.id,
        loggingIn: false
      }
    case logoutUserRoutine.SUCCESS:
      return {
        id: null,
        loggingIn: false
      }
    default:
      return state
  }
}

// WATCHERS
function * loginUserWatcher () {
  yield takeLatest(loginUserRoutine.TRIGGER, loginUser)
}
function * logoutUserWatcher () {
  yield takeLatest(logoutUserRoutine, logoutUser)
}

// SAGAS
export const userSagas = [
  fork(loginUserWatcher),
  fork(logoutUserWatcher)
]
