import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import ModalContent from './ModalContent'

const AddAbsenceModal = ({ user, open, date, handleModalClose, calendarDate, profileView }) => {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  const handleClose = () => {
    setModalOpen(false)
    handleModalClose()
  }

  return (
    <>
      <Modal open={modalOpen} onClose={handleClose} title='Add absence'>
        <ModalContent
          onClose={handleClose}
          type='add'
          data={{
            user,
            date,
            calendarDate,
            viewType: 'calendar'
          }}
          profileView
        />
      </Modal>
    </>
  )
}

export default AddAbsenceModal
