import React from 'react'
import styled from 'styled-components'
import { isNil, propOr } from 'ramda'
import { isNotNil, isNotNilOrEmpty } from 'helpers/ramda'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAdmin } from 'features/auth/ducks/selectors'
import { activateUserRoutine } from 'features/auth/ducks/actions'
import { formatDate } from 'utils/date'

const EmployeeGeneralInfo = ({ user }) => {
  const propOrNot = propName => propOr('---', propName, user)
  const isAdmin = useSelector(selectIsAdmin)
  const dispatch = useDispatch()

  const handleUserActivate = () => {
    dispatch(activateUserRoutine(user.id))
  }

  return (
    <EmployeeGeneralInfoWrapper>
      <AvatarWrapper>
        <UserAvatar>
          {user.avatar && <img src={user.avatar} alt='user-avatar' />}
        </UserAvatar>
      </AvatarWrapper>
      <DetailsWrapper>
        <UserName>
          {user.firstName} {user.lastName}
        </UserName>
        <DetailsItem><span>Nickname:</span><div>{propOrNot('nickname')}</div></DetailsItem>
        <DetailsItem><span>Company Role:</span><div>{propOrNot('companyRole')}</div></DetailsItem>
        <DetailsItem>
          <span>Hire date:</span>
          <div>
            {
              isNotNilOrEmpty(propOr(null, 'employmentDate', user))
                ? formatDate(user.employmentDate)
                : '---'
            }
          </div>
        </DetailsItem>
        <DetailsItem><span>Email:</span><div>{propOrNot('email')}</div></DetailsItem>
        <DetailsItem><span>Phone number:</span><div>{propOrNot('phoneNumber')}</div></DetailsItem>
        <DetailsItem>
          <span>Status:</span>
          <StatusWrapper>
            {
              isNotNil(propOr(null, 'activatedAt', user))
                ? <ActiveStatus>Active</ActiveStatus>
                : <NotActiveStatus>Not active</NotActiveStatus>
            }
            {
              isNil(propOr(null, 'activatedAt', user)) && isAdmin && (
                <ActivateButton onClick={handleUserActivate}>Activate user</ActivateButton>
              )
            }
          </StatusWrapper>
        </DetailsItem>
      </DetailsWrapper>
    </EmployeeGeneralInfoWrapper>
  )
}

export default EmployeeGeneralInfo

const EmployeeGeneralInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`
const AvatarWrapper = styled.div`
  padding-right: 20px;
  height: 100%;
`

const UserAvatar = styled.div`
  img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 5px solid ${({ theme }) => theme.palette.primary.main};
  }
`

const DetailsWrapper = styled.div`
  width: 400px; 
  padding-left: 30px;
`

const DetailsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.secondary.main};

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.common.dustyGray};
  }
  
  & > div {
    width: 200px;
    display: flex;
    justify-content: flex-start;
  }
`

const UserName = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
`

const NotActiveStatus = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.common.white};
`

const ActiveStatus = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.success.main};
  color: ${({ theme }) => theme.palette.common.white};
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ActivateButton = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  transition: all .3s;
  margin-left: 20px;
  
  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};  
  }
`
