import React, { useState } from 'react'
import { Button } from 'de-suite-me-ui'
import { isNotNilOrEmpty } from 'helpers/ramda'
import { addEmployeeNoteRoutine } from 'features/auth/ducks/actions'
import { useDispatch } from 'react-redux'
import Input from 'components/atoms/Input'
import styled from 'styled-components'

const emptyValues = {
  note: '',
  extraDays: null,
  employeesIds: []
}

const BulkAddNoteModal = ({ selectedUsers, callback }) => {
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    dispatch(addEmployeeNoteRoutine({
      note: values.note,
      extraDays: isNotNilOrEmpty(values.extraDays) ? Number(values.extraDays) : null,
      employeesIds: selectedUsers
    }))
    typeof callback === 'function' && callback()
  }

  return (
    <>
      <Input
        label='Note'
        name='note'
        value={values.note}
        onChange={handleValueChange}
      />
      <Input
        label='Extra days'
        type='number'
        name='extraDays'
        inputProps={{
          min: 1
        }}
        value={values.extraDays}
        onChange={handleValueChange}
      />
      <ButtonWrapper>
        <Button
          onClick={handleSubmit}
          color='secondary'
          disabled={!isNotNilOrEmpty(values.note)}
        >
          Add note
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default BulkAddNoteModal

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`
