import React from 'react'
import styled from 'styled-components'

const LoadingScreen = () => {
  return (
    <LoadingScreenWrapper>
      <div className='lds-facebook'>
        <div />
        <div />
        <div />
      </div>
    </LoadingScreenWrapper>
  )
}

export default LoadingScreen

const LoadingScreenWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  
  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(1.5);
    margin-top: -40px;
  }
  
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #ff9347;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
`
