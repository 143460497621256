import React from 'react'
import ReactDOM from 'react-dom'
import { unregister } from './serviceWorker'
import { ThemeProvider } from 'de-suite-me-ui'
import App from 'App'
import ReduxProvider from 'providers/ReduxProvider/ReduxProvider'
import 'react-toastify/dist/ReactToastify.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './App.css'
import { ToastContainer } from 'react-toastify'
import moment from 'moment'

moment.updateLocale('en', {
  week: {
    dow: 1
  }
})

ReactDOM.render(
  <ThemeProvider>
    <ReduxProvider>
      <App />
    </ReduxProvider>
    <ToastContainer />
  </ThemeProvider>,
  document.getElementById('root')
)

unregister()
