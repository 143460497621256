import styled from 'styled-components'

export const WebContent = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export const MobileContent = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`

export const mobileCss = css => `
  @media (max-width: 768px) {
    ${css}
  }
`
