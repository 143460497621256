import React, { useState } from 'react'
import Paper from 'components/atoms/Paper'
import styled from 'styled-components'
import { propOr } from 'ramda'
import { formatDate } from 'utils/date'
import Input from 'components/atoms/Input'
import { Button } from 'de-suite-me-ui'
import { isNotNilOrEmpty } from 'helpers/ramda'
import { useDispatch } from 'react-redux'
import { addEmployeeNoteRoutine } from 'features/auth/ducks/actions'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import Modal from 'components/atoms/Modal'
import EditNoteModal from 'features/EmployeeProfile/components/EditNoteModal'
import RemoveNoteModal from 'features/EmployeeProfile/components/RemoveNoteModal'

const emptyValues = {
  note: '',
  extraDays: null,
  employeesIds: []
}

const EmployeeNotes = ({ user }) => {
  const [values, setValues] = useState(emptyValues)
  const dispatch = useDispatch()
  const [selectedNote, setSelectedNote] = useState({})
  const [removeModalOpen, setRemoveModalOpen] = useState(null)
  const [editModalOpen, setEditModalOpen] = useState(null)

  const handleSubmit = () => {
    dispatch(addEmployeeNoteRoutine({
      note: values.note,
      extraDays: isNotNilOrEmpty(values.extraDays) ? Number(values.extraDays) : null,
      employeesIds: [user.id],
      type: 'employee'
    }))
  }

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleEditModalOpen = note => () => {
    setSelectedNote(note)
    setEditModalOpen(true)
  }

  const handleEditModalClose = () => {
    setSelectedNote({})
    setEditModalOpen(false)
  }

  const handleRemoveModalOpen = note => () => {
    setSelectedNote(note)
    setRemoveModalOpen(true)
  }

  const handleRemoveModalClose = () => {
    setSelectedNote({})
    setRemoveModalOpen(false)
  }

  return (
    <Paper>
      <SectionHeader>
        Add note
      </SectionHeader>
      <NotesForm>
        <Input
          label='Note'
          name='note'
          value={values.note}
          onChange={handleValueChange}
        />
        <Input
          label='Extra days'
          type='number'
          name='extraDays'
          inputProps={{
            min: 1
          }}
          value={values.extraDays}
          onChange={handleValueChange}
        />
        <Button
          onClick={handleSubmit}
          color='primary'
          disabled={!isNotNilOrEmpty(values.note)}
        >
          Add note
        </Button>
      </NotesForm>
      {
        propOr([], 'notes', user).length > 0 && (
          <SectionHeader>
            Employee notes
          </SectionHeader>
        )
      }
      <NotesWrapper length={propOr([], 'notes', user).length}>
        {
          propOr([], 'notes', user).sort((a, b) => b.updatedAt.localeCompare(a.updatedAt)).map(note => {
            return (
              <Note key={note.id}>
                <NoteDate>{formatDate(note.updatedAt)}</NoteDate>
                <NoteContent>
                  {note.note}
                </NoteContent>
                <ExtraDays>
                  <span>extra days:</span> {isNotNilOrEmpty(note.extraDays) ? note.extraDays : '---'}
                </ExtraDays>
                <Tooltip title='Edit note'>
                  <IconWrapper
                    className='edit'
                    onClick={handleEditModalOpen(note)}
                  >
                    <EditOutlinedIcon />
                  </IconWrapper>
                </Tooltip>
                <Tooltip title='Remove note'>
                  <IconWrapper
                    className='remove'
                    onClick={handleRemoveModalOpen(note)}
                  >
                    <DeleteOutlinedIcon />
                  </IconWrapper>
                </Tooltip>
              </Note>
            )
          })
        }
      </NotesWrapper>
      <Modal
        open={editModalOpen}
        title='Edit note'
        onClose={handleEditModalClose}
      >
        <EditNoteModal
          note={selectedNote}
          employeeId={user.id}
          closeModal={handleEditModalClose}
        />
      </Modal>
      <Modal
        open={removeModalOpen}
        title='Remove note'
        onClose={handleRemoveModalClose}
      >
        <RemoveNoteModal
          note={selectedNote}
          employeeId={user.id}
          closeModal={handleRemoveModalClose}
        />
      </Modal>
    </Paper>
  )
}

export default EmployeeNotes

const SectionHeader = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  margin: 10px 0;
  font-weight: bold;
`

const NotesWrapper = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.palette.common.dustyGray};
  width: 100%;
  margin-bottom: 10px;
`

const Note = styled.div`
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.common.alto};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.whisper};
  }
`

const NoteContent = styled.div`
  flex: 1;
  text-align: left;
`

const NoteDate = styled.div`
  width: 100px;
  text-align: left;
`

const ExtraDays = styled.div`
  width: 100px;
  text-align: right;
  margin: 0 50px 0 30px;
  
  span {
    display: inline-block;
    margin-right: 10px;
    color: ${({ theme }) => theme.palette.common.alto};
  }
`

const NotesForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  & > div:nth-of-type(1) {
    flex: 1;
    margin-right: 10px;
  }
  
  & > div:nth-of-type(2) {
    width: 100px;
    margin-right: 10px;
  }
`

const IconWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
  transition: all .3s;
  border-radius: 50%;
  
    &.edit {
    & > * {
      color: ${({ theme }) => theme.palette.secondary.main};
      
      &:hover {
        color: ${({ theme }) => theme.palette.secondary.dark};
      }
    }
  }
  
  &.remove {
    & > * {
      color: ${({ theme }) => theme.palette.error.main};
      
      &:hover {
        color: ${({ theme }) => theme.palette.error.dark};
      }
    }
  }
`
