import React from 'react'
import styled from 'styled-components'

const Paper = (props) => (
  <PaperWrapper {...props}>
    {props.children}
  </PaperWrapper>
)

export default Paper

const PaperWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  border: solid 1px ${({ theme }) => theme.palette.common.mercury};
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 15px 25px;
  margin-bottom: 20px;
`
