import React, { useEffect, useRef } from 'react'
import { Switch, Route, Router } from 'react-router-dom'
import styled from 'styled-components'
import PrivateRoute from 'components/PrivateRoute'
import { PATHS } from 'utils/paths'
import Login from 'features/auth/Login'
import Dashboard from 'features/dashboard/Dashboard'
import history from 'utils/history'
import Navigation from 'features/navigation/Navigation'
import { getCurrentUser, selectIsFetchingUser } from 'features/auth/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import Calendar from 'features/calendar/Calendar'
import UserProfile from 'features/userProfile/UserProfile'
import { isEmpty, propOr } from 'ramda'
import { fetchAuthUserRoutine } from 'features/auth/ducks/actions'
import LoadingScreen from 'components/LoadingScreen'
import storageService from 'services/StorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import AnimatedBackground from 'components/AnimatedBackground'
import GlobalLoader from 'components/GlobalLoader'
import { fetchAllHolidaysRoutine } from 'features/calendar/ducks/actions'
import moment from 'moment'
import EmployeeProfile from 'features/EmployeeProfile/EmployeeProfile'
import UsersList from 'features/usersList/UsersList'
import { isNotNil } from 'helpers/ramda'
import NotFound from 'components/NotFound'
import { MobileContent, mobileCss, WebContent } from 'components/RWD'
import MobileNavigation from 'features/navigation/MobileNavigation'

function App () {
  const currentUser = useSelector(getCurrentUser)
  const dispatch = useDispatch()
  const isLoggedIn = !isEmpty(currentUser)
  const isFetchingUser = useSelector(selectIsFetchingUser)
  const isUserActive = isNotNil(propOr(null, 'activatedAt', currentUser))
  const topRef = useRef()

  useEffect(() => {
    const id = storageService.get(LOCAL_STORAGE_KEYS.currentUserId)
    dispatch(fetchAuthUserRoutine(id))
    dispatch(fetchAllHolidaysRoutine(moment().year()))
  }, [])

  history.listen(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
  })

  return (
    <BodyContainer isLoggedIn={isLoggedIn}>
      <span ref={topRef} />
      <WebContent>
        {isLoggedIn && isUserActive && <Navigation />}
      </WebContent>
      <MobileContent>
        {isLoggedIn && isUserActive && <MobileNavigation />}
      </MobileContent>
      {isLoggedIn && isUserActive && <AnimatedBackground />}
      {
        isFetchingUser
          ? <LoadingScreen />
          : (
            <Router history={history}>
              <GlobalLoader />
              <Switch>
                <Route exact path={PATHS.login} component={Login} />
                <PrivateRoute
                  exact
                  isAuthenticated={isLoggedIn}
                  path={PATHS.dashboard}
                  component={Dashboard}
                />
                <PrivateRoute
                  exact
                  isAuthenticated={isLoggedIn}
                  path={PATHS.userPanel}
                  component={UserProfile}
                />
                <PrivateRoute
                  exact
                  isAuthenticated={isLoggedIn}
                  path={PATHS.calendar}
                  component={Calendar}
                  webOnly
                />
                <PrivateRoute
                  isAuthenticated={isLoggedIn}
                  path={PATHS.employeeProfile}
                  component={EmployeeProfile}
                  webOnly
                />
                <PrivateRoute
                  isAuthenticated={isLoggedIn}
                  path={PATHS.users}
                  component={UsersList}
                  webOnly
                />
                <Route component={NotFound} />
              </Switch>
            </Router>
          )
      }
    </BodyContainer>
  )
}

export default App

const BodyContainer = styled.div`
  box-sizing: border-box;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  overflow-y: auto;
  padding: ${({ isLoggedIn }) => isLoggedIn ? '40px 30px 60px 90px' : '40px 30px; 40px 60px'}; 
  color: ${({ theme }) => theme.palette.common.black};
  
  ${({ isLoggedIn }) => mobileCss(`
    padding: ${isLoggedIn ? '30px 20px 50px 20px' : '40px 30px; 40px 60px'};
    height: unset;
  `)}
`
