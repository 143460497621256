import httpClientService from 'services/httpClientService'

const api = httpClientService()

export const fetchEmployee = id => api.get(`/employees/${id}`)
export const fetchEmployeeLogs = id => api.get(`/employees/audit-log/${id}`)
export const fetchAllEmployees = () => api.get('/employees')
export const deleteEmployee = id => api.delete(`/employees/${id}`)
export const updateEmployee = data => api.patch(`/employees/${data.id}`, data)
export const activateEmployee = id => api.patch(`employees/activate/${id}`)
export const addEmployeeNote = data => api.post('/employees/note', data)
export const updateEmployeeNote = data => api.patch(`/employees/note/${data.id}`, data)
export const deleteEmployeeNote = ({ id }) => api.delete(`/employees/note/${id}`)
export const addExtraDays = data => api.patch('/employees/add-holiday-days', data)
export const getEmployeeLogs = employee => api.get(`/employees/audit-log/${employee}`)
