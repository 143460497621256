import React from 'react'
import styled from 'styled-components'

const CalendarPaper = ({ children }) => (
  <CalendarPaperWrapper>
    {children}
  </CalendarPaperWrapper>
)

export default CalendarPaper

const CalendarPaperWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
  border: solid 1px ${({ theme }) => theme.palette.common.mercury};
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 15px 25px;
`
