import httpClientService from 'services/httpClientService'

const api = httpClientService()

export const fetchCalendarData = payload => api.get(`/calendar/${payload.year}/${payload.month ? payload.month : ''}`)
export const addAbsence = payload => api.post('/absence', payload)
export const updateAbsence = absence => api.patch(`/absence/${absence.id}`, absence)
export const deleteAbsence = absenceId => api.delete(`/absence/${absenceId}`)
export const deleteSingleDayFromAbsence = payload => api.delete(`/absence/${payload.absenceId}/delete-day/${payload.date}`)
export const fetchAllHolidays = year => api.get(`/days-off/${year}`)
export const fetchPendingAbsences = () => api.get('/absence/pending-for-cancellation')
