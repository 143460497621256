import React from 'react'
import styled from 'styled-components'
import { ABSENCE_TYPES } from 'utils/absences'
import { isEmpty, values } from 'ramda'

const AbsenceSection = ({ absences, type }) => {
  return !isEmpty(absences[type]) && (
    <AbsencesSectionWrapper>
      <AbsenceTitle>
        <img src={ABSENCE_TYPES[type].icon} alt='absence-section-icon' />
        {ABSENCE_TYPES[type].label}
      </AbsenceTitle>
      <AbsencesListWrapper>
        {
          absences[type].map((absence, index) => {
            return (
              <AbsenceUsername
                key={absence.id}
              >
                {absence.userName}
                {index !== absences[type].length - 1 ? ', ' : ''}
              </AbsenceUsername>
            )
          })
        }
      </AbsencesListWrapper>
    </AbsencesSectionWrapper>
  )
}

const AbsencesList = ({ absences }) => {
  return (
    <AbsencesWrapper>
      <AbsenceSection absences={absences} type='holiday' />
      <AbsenceSection absences={absences} type='conference' />
      <AbsenceSection absences={absences} type='sick_leave' />
      <AbsenceSection absences={absences} type='childcare' />
      <AbsenceSection absences={absences} type='unpaid_holiday' />
      <AbsenceSection absences={absences} type='other' />
      <AbsenceSection absences={absences} type='home_office' />
      {
        absences && values(absences).every(absence => isEmpty(absence)) && (
          <EmptyState>
            There are no absences on that day.
          </EmptyState>
        )
      }
    </AbsencesWrapper>
  )
}

export default AbsencesList

const AbsencesWrapper = styled.div`
  
`

const AbsenceTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  
  img {
    width: 25px;
    margin-right: 10px;
  }
`

const AbsencesSectionWrapper = styled.div`
  margin-bottom: 10px;
`

const AbsencesListWrapper = styled.div`
  margin-top: 5px;
  padding-left: 35px;
`

const AbsenceUsername = styled.span`
  display: inline-block;
  margin-right: 7px;
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.common.dustyGray};
`

const EmptyState = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.common.dustyGray};
`
