import debounce from 'lodash.debounce'

export const validateField = (schema, values) => {
  return debounce((name, actionHandler = () => {}) => {
    return (
      schema
        .validateAt(name, values)
        .then(() => {
          actionHandler({ valid: true, errors: '' })
        })
        .catch(actionHandler)
    )
  }, 200)
}

export const validateValues = schema =>
  debounce((values, callback) => schema.isValid(values).then(callback), 400)
