import React from 'react'
import styled from 'styled-components'
import avatarWrapperImage from 'assets/images/avatarWrapper.png'
import { isNotNilOrEmpty } from 'helpers/ramda'
import { mobileCss } from 'components/RWD'

const UserGeneralInfo = ({ currentUser }) => {
  return (
    <UserGeneralInfoWrapper>
      <SectionHeaderWrapper>
        <SectionHeaderText>
          My Profile
        </SectionHeaderText>
      </SectionHeaderWrapper>
      <UserAvatar avatar={currentUser.avatar}>
        <img src={avatarWrapperImage} alt='user-avatar' />
      </UserAvatar>
      <UserName>
        {currentUser.fullName}
      </UserName>
      {
        isNotNilOrEmpty(currentUser.nickname) && (
          <UserNickname>
            {currentUser.nickname}
          </UserNickname>
        )
      }
      <UserCompanyRole>
        {isNotNilOrEmpty(currentUser.companyRole) && `${currentUser.companyRole} • `}
        {isNotNilOrEmpty(currentUser.type) && <span>{currentUser.type.toUpperCase()}</span>}
      </UserCompanyRole>
    </UserGeneralInfoWrapper>
  )
}

export default UserGeneralInfo

const UserGeneralInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const SectionHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SectionHeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.dark};
`

const UserAvatar = styled.div`
  width: 160px;
  height: 140px;
  overflow: hidden;
  background: ${({ avatar }) => `transparent url(${avatar}) no-repeat center/contain`};
  margin-left: 15px;
  
  img {
    width: 160px;
    height: 140px;
  }
  
  ${mobileCss(`
    background-size: auto 95%;
  `)}
`

const UserName = styled.div`
  font-size: 16px;
  letter-spacing: .5px;
`

const UserCompanyRole = styled.div`
  font-size: 11px;
  
  span {
    font-weight: bold;
  }
`

const UserNickname = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
`
