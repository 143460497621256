import React from 'react'
import { useSelector } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import styled from 'styled-components'
import { selectGlobalLoader } from 'ducks/loaderSelector'
import { withStyles } from '@material-ui/core'

const ProgressBar = () => {
  const isLoading = useSelector(selectGlobalLoader)

  const StyledLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: '#ffb06b26'
    },
    barColorPrimary: {
      backgroundColor: '#ff9347'
    }
  })(LinearProgress)

  return isLoading && (
    <GlobalLoader>
      <StyledLinearProgress color='primary' />
    </GlobalLoader>
  )
}

const GlobalLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
`

export default ProgressBar
