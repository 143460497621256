import React, { useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { isNotEmpty } from 'helpers/ramda'
import { ABSENCE_TYPES } from 'utils/absences'
import { formatDate } from 'utils/date'
import { CalendarIcon } from 'de-suite-me-ui'

export const ActiveAbsence = ({ absence }) => {

  return (
    <Absence>
      <img src={ABSENCE_TYPES[absence.type].icon} alt='absence-icon' />
      <div>
        <AbsenceDates>
          {formatDate(absence.dateFrom)} - {formatDate(absence.dateTo)}
        </AbsenceDates>
        <AbsenceDetails>
          {ABSENCE_TYPES[absence.type].label}
          <Bullet>•</Bullet>
          <CalendarIcon />
          <WorkingDays>
            {absence.workingDays} {absence.workingDays <= 1 ? 'day' : 'days'}
          </WorkingDays>
        </AbsenceDetails>
      </div>
    </Absence>
  )
}

const UserActiveAbsences = ({ currentUser }) => {
  const activeAbsences = useMemo(() => {
    const absences = currentUser.absences
      ? currentUser.absences
        .filter(absence =>
          moment(absence.dateTo).isSameOrAfter(moment(), 'day') && absence.workingDays > 0)
      : []
    return absences.sort((a, b) => a.dateFrom.localeCompare(b.dateFrom))
  }, [currentUser])

  return (
    <ActiveAbsencesWrapper>
      <SectionHeader>
        Active and future absences
      </SectionHeader>
      {
        isNotEmpty(activeAbsences) && activeAbsences.map(absence => {
          return (
            <ActiveAbsence key={absence.id} absence={absence} />
          )
        })
      }
    </ActiveAbsencesWrapper>
  )
}

export default UserActiveAbsences

const ActiveAbsencesWrapper = styled.div`
  margin-top: 30px;
`

const SectionHeader = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  margin-bottom: 10px;
  font-weight: bold;
`

const Absence = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  
  img {
    width: 30px;
    margin-right: 20px;
  }
`

const AbsenceDates = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
`

const AbsenceDetails = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.dustyGray};
`

const Bullet = styled.div`
  margin: 0 7px;
`

const WorkingDays = styled.div`
  margin-left: 5px;
`
