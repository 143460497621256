import React, { useEffect, useState } from 'react'
import Modal from 'components/atoms/Modal'
import RemoveAbsenceModal from 'features/userProfile/components/RemoveAbsenceModal'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import ModalContent from './ModalContent'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import RemoveSingleDayModal from 'features/userProfile/components/RemoveSingleDayModal'
import { propOr } from 'ramda'
import { useMemo } from 'react'

const UpdateAbsenceModal = ({
  open,
  user,
  absence,
  date,
  onModalClose,
  viewType,
  calendarDate,
  profileView
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [status, setStatus] = useState('edit')

  const titleText = useMemo(() => {
    switch (status) {
      case 'edit':
        return 'Edit absence'
      case 'remove':
        return 'Remove absence'
      case 'single':
        return 'Remove Single Day'
      default:
        return 'Edit absence'
    }
  }, [status])

  useEffect(() => {
    setModalOpen(open)
  }, [open])

  const handleClose = () => {
    setModalOpen(false)
    setStatus('edit')
    onModalClose()
  }

  const handleStatus = status => {
    setStatus(status)
  }

  return (
    <Modal open={modalOpen} onClose={handleClose} titleNoHover={titleText}>
      {status === 'edit' && (
        <ModalContent
          onClose={handleClose}
          type='edit'
          data={{
            user,
            absence,
            date,
            viewType
          }}
          profileView={profileView}
        />
      )}
      {status === 'remove' && (
        <RemoveAbsenceModal
          closeModal={handleClose}
          absence={absence}
          viewType={viewType}
          currentUser={user}
          clickedDate={calendarDate}
        />
      )}
      {status === 'single' && (
        <RemoveSingleDayModal
          closeModal={handleClose}
          absence={absence}
          viewType={viewType}
          currentUser={user}
          clickedDate={calendarDate}
        />
      )}
      <IconWrapper>
        {['remove', 'single'].includes(status) && (
          <Tooltip title={'Edit absence'}>
            <Icon className='edit'>
              <EditOutlinedIcon
                onClick={() => {
                  handleStatus('edit')
                }}
              />
            </Icon>
          </Tooltip>
        )}
        {['edit', 'single'].includes(status) && (
          <Tooltip title={'Remove absence'}>
            <Icon className='remove'>
              <DeleteOutlinedIcon
                onClick={() => {
                  handleStatus('remove')
                }}
              />
            </Icon>
          </Tooltip>
        )}
        {['remove', 'edit'].includes(status) && (
          <Tooltip title={'Remove single day'}>
            <Icon className='single'>
              {propOr(null, 'workingDays', absence) > 1 && (
                <EventBusyIcon onClick={() => handleStatus('single')} />
              )}
            </Icon>
          </Tooltip>
        )}
      </IconWrapper>
    </Modal>
  )
}

export default UpdateAbsenceModal

const IconWrapper = styled.div`
  display: flex;
  margin-top: -19px;
`
const Icon = styled.div`
  display: flex;
  cursor: pointer;
  transition: all 0.3s;

  &.single {
    margin-left: 5px;
    & > * {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    &:hover {
      & > * {
        color: ${({ theme }) => theme.palette.primary.dark};
      }
    }
  }

  &.edit {
    & > * {
      color: ${({ theme }) => theme.palette.secondary.main};

      &:hover {
        color: ${({ theme }) => theme.palette.secondary.dark};
      }
    }
  }

  &.remove {
    & > * {
      color: ${({ theme }) => theme.palette.error.main};

      &:hover {
        color: ${({ theme }) => theme.palette.error.dark};
      }
    }
  }
`
