import store from 'store'

const get = instance => (key, defaultValue = null) => instance.get(key, defaultValue)
const set = instance => (key, value) => instance.set(key, value)
const remove = instance => key => instance.remove(key)
const clear = instance => () => instance.clearAll()

export const storageService = (instance = store) => ({
  get: get(instance),
  set: set(instance),
  remove: remove(instance),
  clear: clear(instance)
})

export default storageService()
