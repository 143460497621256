import React from 'react'
import styled from 'styled-components'

const NotFound = () => {
  return (
    <PageWrapper>
      <Header>nope</Header>
      <Subheader>there is no such page</Subheader>
      <SmallText>(404 or something)</SmallText>
    </PageWrapper>
  )
}

export default NotFound

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.dustyGray};
`

const Header = styled.div`
  font-size: 100px;
  text-align: center;
  margin-bottom: 60px;
`

const Subheader = styled.div`
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
`

const SmallText = styled.div`
  font-size: 20px;
`
