import { createSelector } from 'reselect'
import { pipe, prop, propOr, map, isNil, filter, not, isEmpty, flatten } from 'ramda'
import moment from 'moment'
import { sortBy } from 'lodash'

const getCalendarState = state => state.calendar

export const selectCalendarData = id => createSelector(
  getCalendarState,
  (calendarState) => {
    return sortBy(calendarState.users, ['firstName'])
  }
)

export const selectHolidays = createSelector(
  getCalendarState,
  propOr([], 'holidays')
)

export const selectTodayAbsences = createSelector(
  getCalendarState,
  pipe(
    prop('users'),
    map(
      user => {
        const absences = propOr([], 'absences', user).map(absence => {
          return moment().isBetween(absence.dateFrom, absence.dateTo, 'day', '[]')
            ? ({
              userName: user.fullName,
              ...absence
            })
            : null
        })

        return absences.filter(absence => !isNil(absence))
      }
    ),
    filter(
      pipe(
        isEmpty,
        not
      )
    ),
    flatten
  )
)

export const selectTomorrowAbsences = createSelector(
  getCalendarState,
  pipe(
    prop('users'),
    map(
      user => {
        const absences = propOr([], 'absences', user).map(absence => {
          return moment().add(1, 'day')
            .isBetween(absence.dateFrom, absence.dateTo, 'day', '[]')
            ? ({
              userName: user.fullName,
              ...absence
            })
            : null
        })
        return absences.filter(absence => !isNil(absence))
      }
    ),
    filter(
      pipe(
        isEmpty,
        not
      )
    ),
    flatten
  )
)
