import React from 'react'
import styled from 'styled-components'

const UserHolidayStats = ({ currentUser }) => {
  return (
    <SectionWrapper>
      <SectionHeader>
        Holiday
      </SectionHeader>
      <UserHolidayStatsWrapper>
        <StatWrapper>
          {currentUser.availableHolidayDays} / {currentUser.annualHolidayDays}
          <span>This year</span>
        </StatWrapper>
        <StatWrapper>
          {currentUser.holidayDaysFromPrevYear} / {currentUser.annualHolidayDays}
          <span>Previous year</span>
        </StatWrapper>
      </UserHolidayStatsWrapper>
      {
        currentUser.annualChildcareDays > 0 && (
          <UserHolidayStatsWrapper>
            <StatWrapper>
              {currentUser.availableChildcareDays} / {currentUser.annualChildcareDays}
              <span>Childcare</span>
            </StatWrapper>
          </UserHolidayStatsWrapper>
        )
      }
    </SectionWrapper>
  )
}

export default UserHolidayStats

const SectionWrapper = styled.div`
  margin-top: 25px;
`

const SectionHeader = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  margin-bottom: 10px;
  font-weight: bold;
`

const UserHolidayStatsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 60px;
  padding: 7px 0;
  border-radius: 17px;
  background-color: ${({ theme }) => theme.palette.common.whisper};
  margin-bottom: 10px;
`

const StatWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  font-size: 18px;
  font-weight: bold;
  
  span {
    font-size: 12px;
    font-weight: normal;
  }
  
  &:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.palette.common.dustyGray};  
  }
`
