import { loginUserRoutine, logoutUserRoutine } from 'ducks/user'
import emptyAuthState from 'features/auth/ducks/state'
import {
  fetchAuthUserRoutine,
  fetchEmployeeRoutine,
  fetchCurrentUserRoutine,
  updateUserProfileRoutine,
  fetchAllEmployeesRoutine, fetchEmployeeLogsRoutine
} from 'features/auth/ducks/actions'

export default (state = emptyAuthState, action) => {
  switch (action.type) {
    case loginUserRoutine.REQUEST:
      return {
        ...state
      }
    case fetchAuthUserRoutine.REQUEST:
      return {
        ...state,
        isFetchingUser: true
      }
    case loginUserRoutine.SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    case fetchAuthUserRoutine.SUCCESS:
      return {
        ...state,
        user: action.payload,
        isFetchingUser: false
      }
    case fetchAuthUserRoutine.FAILURE:
      return {
        ...state,
        user: {},
        isFetchingUser: false
      }
    case logoutUserRoutine.SUCCESS:
      return {
        ...state,
        user: {}
      }
    case updateUserProfileRoutine.SUCCESS:
      return {
        ...state,
        user: {
          ...action.payload,
          absences: state.user.absences
        }
      }
    case fetchCurrentUserRoutine.SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    case fetchEmployeeRoutine.SUCCESS:
      return {
        ...state,
        employee: action.payload
      }
    case fetchAllEmployeesRoutine.SUCCESS:
      return {
        ...state,
        allEmployees: action.payload
      }
    case fetchEmployeeLogsRoutine.SUCCESS:
      return {
        ...state,
        logs: action.payload
      }
    default:
      return state
  }
}
