import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAdmin, selectSelectedEmployee } from 'features/auth/ducks/selectors'
import Paper from 'components/atoms/Paper'
import EmployeeGeneralInfo from 'features/EmployeeProfile/components/EmployeeGeneralInfo'
import UserProfileAbsences from 'features/userProfile/components/UserProfileAbsences'
import EmployeeSettings from 'features/EmployeeProfile/components/EmployeeSettings'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { pathOr } from 'ramda'
import { PATHS } from 'utils/paths'
import { redirect } from 'utils/router'
import { fetchEmployeeLogsRoutine, fetchEmployeeRoutine } from 'features/auth/ducks/actions'
import EmployeeNotes from 'features/EmployeeProfile/components/EmployeeNotes'

const EmployeeProfile = ({ location }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const employee = useSelector(selectSelectedEmployee)
  const isAdmin = useSelector(selectIsAdmin)

  const backDetails = useMemo(() => {
    const origin = pathOr('', ['state', 'origin'], location)
    return origin === 'admin'
      ? {
        label: 'users list',
        path: PATHS.users
      }
      : {
        label: 'calendar',
        path: PATHS.calendar
      }
  }, [location])

  useEffect(() => {
    dispatch(fetchEmployeeRoutine(id))
    dispatch(fetchEmployeeLogsRoutine(id))
  }, [id])

  return (
    <PageWrapper>
      <BackButton onClick={() => redirect(backDetails.path)}>
        <ArrowBackIcon />
        <div>Back to {backDetails.label}</div>
      </BackButton>
      <Paper>
        <EmployeeGeneralInfo user={employee} />
      </Paper>
      {isAdmin && <EmployeeSettings user={employee} />}
      {isAdmin && <EmployeeNotes user={employee} />}
      {/*{isAdmin && <EmployeeLogs user={employee} />}*/}
      <UserProfileAbsences currentUser={employee} />
    </PageWrapper>
  )
}

export default EmployeeProfile

const PageWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`

const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
  
  & > div {
    margin-left: 10px;
  }
`
