import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Button, Input } from 'de-suite-me-ui'
import { pick, equals, isEmpty, isNil } from 'ramda'
import { updateUserProfileRoutine } from 'features/auth/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsUpdatingUser } from 'ducks/loaderSelector'

const UserSettings = ({ currentUser }) => {
  const [values, setValues] = useState({})
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsUpdatingUser)

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: isEmpty(value) ? null : value }))
  }

  const isButtonDisabled = useMemo(() => {
    const userDetails = pick(['companyRole', 'nickname', 'phoneNumber'], currentUser)
    const pickedValues = pick(['companyRole', 'nickname', 'phoneNumber'], values)
    return equals(userDetails, pickedValues)
  }, [currentUser, values])

  const isPhoneValid = useMemo(() => {
    return /^\d{9}$/.test(values.phoneNumber) || isNil(values.phoneNumber)
  }, [values.phoneNumber])

  const handleSubmit = () => {
    dispatch(updateUserProfileRoutine(values))
  }

  useEffect(() => {
    setValues(currentUser)
  }, [currentUser])

  return (
    <UserSettingsWrapper>
      <SectionHeaderWrapper>
        <SectionHeaderText>
          Settings
        </SectionHeaderText>
      </SectionHeaderWrapper>
      <FormWrapper>
        <Input
          name='companyRole'
          value={values.companyRole}
          onChange={e => handleValueChange('companyRole', e.target.value)}
          label='Company role'
        />
        <Input
          name='nickname'
          value={values.nickname}
          onChange={e => handleValueChange('nickname', e.target.value)}
          label='Nickname'
        />
        <Input
          name='phoneNumber'
          value={values.phoneNumber}
          onChange={e => handleValueChange('phoneNumber', e.target.value)}
          label='Phone Number'
          error={!isPhoneValid}
          errorText='Incorrect phone number'
        />
      </FormWrapper>
      <ButtonWrapper>
        <Button
          color='secondary'
          disabled={isButtonDisabled || !isPhoneValid || isLoading}
          onClick={handleSubmit}
        >
          Save changes
        </Button>
      </ButtonWrapper>
    </UserSettingsWrapper>
  )
}

export default UserSettings

const UserSettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const SectionHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SectionHeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.dark};
`

const FormWrapper = styled.div`
  margin: 20px 0;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
