import React, { useEffect, useState } from 'react'
import { Input as DeSuiteInput } from 'de-suite-me-ui'
import { head } from 'ramda'

export const Input = props => {
  const {
    name,
    type,
    onChange,
    validate,
    value: initialValue,
    clearValueTrigger,
    onBlur,
    onFocus
  } = props
  const [touched, _setTouched] = useState(false)
  const [value, _setValue] = useState(initialValue || '')
  const [{ valid, error }, _validate] = useState({
    valid: true,
    error: ''
  })

  useEffect(() => {
    if (touched) {
      validate(name, v => {
        _validate({ valid: v.valid, error: v.errors })
      })
    }
  }, [value, touched])

  useEffect(() => {
    type === 'date' ? _setValue(null) : _setValue('')
  }, [clearValueTrigger])

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  const handleBlur = () => {
    _setTouched(true)
    typeof onBlur === 'function' && onBlur()
  }
  const handleFocus = () => {
    typeof onFocus === 'function' && onFocus()
  }
  const handleChange = e => {
    _setValue(e.target.value)
    onChange(name, e.target.value)
  }

  return (
    <DeSuiteInput
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      error={touched && !valid}
      errorText={head(error)}
    />
  )
}

Input.defaultProps = {
  onChange: () => {},
  validate: () => {},
  variant: 'outlined'
}

export default Input
