import { createRoutine } from 'redux-saga-routines'
import { takeLatest, call, fork, put } from 'redux-saga/effects'
import * as CalendarService from 'services/CalendarService'
import { dissoc, omit } from 'ramda'
import { extractMultipleMessagesFromErrorResponse } from 'utils/errors'
import { toast } from 'react-toastify'
import {
  fetchCurrentUserRoutine,
  fetchEmployeeRoutine
} from 'features/auth/ducks/actions'
import moment from 'moment'

export const fetchCalendarDataRoutine = createRoutine('FETCH_CALENDAR_DATA')
export const fetchAllHolidaysRoutine = createRoutine('FETCH_ALL_HOLIDAYS')
export const addAbsenceRoutine = createRoutine('ADD_ABSENCE')
export const updateAbsenceRoutine = createRoutine('UPDATE_ABSENCE')
export const deleteAbsenceRoutine = createRoutine('DELETE_ABSENCE')
export const fetchPendingAbsencesRoutine = createRoutine(
  'FETCH_PENDING_ABSENCES'
)
export const deleteSingleDayFromAbsenceRoutine = createRoutine(
  'DELETE_SINGLE_DAY_FROM_ABSENCE'
)

function * fetchPendingAbsences () {
  yield put(fetchPendingAbsencesRoutine.request())
  try {
    const result = yield call(CalendarService.fetchPendingAbsences)
    yield put(fetchPendingAbsencesRoutine.success(result))
  } catch (err) {
    yield put(fetchPendingAbsencesRoutine.failure(err))
    console.error(err)
  }
}

function * fetchCalendarData ({ payload }) {
  const { hideLoader } = payload
  yield put(fetchCalendarDataRoutine.request(hideLoader))
  try {
    const { data } = yield call(CalendarService.fetchCalendarData, payload)
    yield put(fetchCalendarDataRoutine.success(data.data))
  } catch (err) {
    yield put(fetchCalendarDataRoutine.failure(err))
    console.error(err)
  }
}

function * addAbsence ({ payload }) {
  const { callback, viewType, employeeId, clickedDate } = payload
  const month = Number(moment(clickedDate).format('MM'))

  yield put(addAbsenceRoutine.request())
  try {
    const { data } = yield call(
      CalendarService.addAbsence,
      omit(['callback', 'clickedDate'], payload)
    )
    if (viewType === 'dashboard') {
      yield put(fetchCurrentUserRoutine(employeeId))
    } else if (viewType === 'calendar') {
      yield put(
        fetchCalendarDataRoutine({
          year: moment().year(),
          month
        })
      )
    } else {
      yield put(fetchEmployeeRoutine(employeeId))
    }
    yield put(addAbsenceRoutine.success(data.data))
    toast.success('Your absence has been added.')
    typeof callback === 'function' && callback()
  } catch (err) {
    yield put(addAbsenceRoutine.failure(err))
    toast.error(extractMultipleMessagesFromErrorResponse(err))
    console.error(err)
  }
}

function * updateAbsence ({ payload }) {
  const { viewType, callback, employeeId, clickedDate  } = payload
  const month = Number(moment(clickedDate).format('MM'))

  yield put(updateAbsenceRoutine.request())
  try {
    const { data } = yield call(
      CalendarService.updateAbsence, omit(['callback', 'viewType', 'clickedDate'], payload)
    )
    yield put(updateAbsenceRoutine.success(data.data))
    if (viewType === 'dashboard') {
      yield put(fetchCurrentUserRoutine(employeeId))
    } else if (viewType === 'calendar') {
      yield put(
        fetchCalendarDataRoutine({
          year: moment().year(),
          month
        })
      )
    } else {
      yield put(fetchEmployeeRoutine(employeeId))
    }
    typeof callback === 'function' && callback()
    toast.success('Absence has been updated.')
  } catch (err) {
    yield put(updateAbsenceRoutine.failure(err))
    toast.error(extractMultipleMessagesFromErrorResponse(err))
    console.error(err)
  }
}

function * deleteAbsence ({ payload }) {
  const { viewType, userId, clickedDate } = payload

  yield put(deleteAbsenceRoutine.request())
  try {
    yield call(CalendarService.deleteAbsence, payload.absenceId)
    yield put(deleteAbsenceRoutine.success(dissoc('clickedDate', payload)))
    if (viewType === 'dashboard') {
      yield put(fetchCurrentUserRoutine(userId))
    } else if (viewType === 'calendar') {
      yield put(
        fetchCalendarDataRoutine({
          year: moment().year(),
          month: clickedDate.month
        })
      )
    } else {
      yield put(fetchEmployeeRoutine(userId))
    }
    toast.success('Absence has been removed.')
  } catch (err) {
    yield put(deleteAbsenceRoutine.failure(err))
    toast.error(extractMultipleMessagesFromErrorResponse(err))
    console.error(err)
  }
}

function * deleteSingleDayFromAbsence ({ payload }) {
  const { viewType, userId, clickedDate } = payload

  yield put(deleteSingleDayFromAbsenceRoutine.request())
  try {
    yield call(CalendarService.deleteSingleDayFromAbsence, payload)
    if (viewType === 'dashboard') {
      yield put(fetchCurrentUserRoutine(userId))
      yield put(fetchEmployeeRoutine(userId))
    } else if (viewType === 'calendar') {
      yield put(
        fetchCalendarDataRoutine({
          year: moment().year(),
          month: clickedDate.month
        })
      )
    } else {
      yield put(fetchEmployeeRoutine(userId))
    }
    yield put(deleteSingleDayFromAbsenceRoutine.success())
    toast.success(`Day ${payload.date} has been removed from the absence.`)
  } catch (err) {
    yield put(deleteSingleDayFromAbsenceRoutine.failure(err))
    toast.error(extractMultipleMessagesFromErrorResponse(err))
    console.error(err)
  }
}

function * fetchAllHolidays ({ payload }) {
  yield put(fetchAllHolidaysRoutine.request())
  try {
    const { data } = yield call(CalendarService.fetchAllHolidays, payload)
    yield put(fetchAllHolidaysRoutine.success(data.data))
  } catch (err) {
    console.error(err)
  }
}

// WATCHERS
function * fetchCalendarDataWatcher () {
  yield takeLatest(fetchCalendarDataRoutine.TRIGGER, fetchCalendarData)
}

function * fetchAllHolidaysWatcher () {
  yield takeLatest(fetchAllHolidaysRoutine.TRIGGER, fetchAllHolidays)
}

function * addAbsenceWatcher () {
  yield takeLatest(addAbsenceRoutine.TRIGGER, addAbsence)
}

function * updateAbsenceWatcher () {
  yield takeLatest(updateAbsenceRoutine.TRIGGER, updateAbsence)
}

function * deleteAbsenceWatcher () {
  yield takeLatest(deleteAbsenceRoutine.TRIGGER, deleteAbsence)
}

function * fetchPendingAbsencesWatcher () {
  yield takeLatest(fetchPendingAbsencesRoutine.TRIGGER, fetchPendingAbsences)
}

function * deleteSingleDayFromAbsenceWatcher () {
  yield takeLatest(
    deleteSingleDayFromAbsenceRoutine.TRIGGER,
    deleteSingleDayFromAbsence
  )
}

// SAGAS
export const calendarSagas = [
  fork(fetchCalendarDataWatcher),
  fork(addAbsenceWatcher),
  fork(fetchAllHolidaysWatcher),
  fork(updateAbsenceWatcher),
  fork(deleteAbsenceWatcher),
  fork(fetchPendingAbsencesWatcher),
  fork(deleteSingleDayFromAbsenceWatcher)
]
