import homeOfficeIcon from 'assets/images/home-office.svg'
import sickLeaveIcon from 'assets/images/sick-leave.svg'
import holidayIcon from 'assets/images/holiday.svg'
import childcareIcon from 'assets/images/childcare.svg'
import conferenceIcon from 'assets/images/conference.svg'
import unpaidIcon from 'assets/images/unpaid.svg'
import otherIcon from 'assets/images/other.svg'

export const ABSENCE_TYPES = {
  home_office: {
    value: 'home_office',
    label: 'Home office',
    icon: homeOfficeIcon
  },
  sick_leave: {
    value: 'sick_leave',
    label: 'Sick leave',
    icon: sickLeaveIcon
  },
  holiday: {
    value: 'holiday',
    label: 'Holiday',
    icon: holidayIcon
  },
  childcare: {
    value: 'childcare',
    label: 'Childcare',
    icon: childcareIcon
  },
  conference: {
    value: 'conference',
    label: 'Conference',
    icon: conferenceIcon
  },
  unpaid_holiday: {
    value: 'unpaid_holiday',
    label: 'Unpaid Holiday',
    icon: unpaidIcon
  },
  other: {
    value: 'other',
    label: 'Other',
    icon: otherIcon
  }
}

export const sortAbsencesByType = absences => {
  const sorted = {
    home_office: [],
    sick_leave: [],
    holiday: [],
    childcare: [],
    conference: [],
    unpaid_holiday: [],
    other: []
  }

  absences && absences.forEach(absence => {
    sorted[absence.type].push(absence)
  })

  return sorted
}
