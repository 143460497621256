import { mergeDeepRight } from 'ramda'
import axios from 'axios'
import storageService from 'services/StorageService'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import applyConverters from 'axios-case-converter'

const apiUrl = process.env.REACT_APP_API_URL

export const mergeConfig = config => {
  const defaultConfig = ({
    headers: {
      Pragma: 'no-cache',
      Authorization: storageService.get(LOCAL_STORAGE_KEYS.token)
    }
  })

  return mergeDeepRight(defaultConfig)(config)
}

const get = instance => async (url, config = {}) => {
  return instance.get(url, mergeConfig(config))
}

const post = instance => async (url, data = {}, config = {}) => {
  return instance.post(url, data, mergeConfig(config))
}

const put = instance => async (url, data = {}, config = {}) => {
  return instance.put(url, data, mergeConfig(config))
}

const patch = instance => async (url, data = {}, config = {}) => {
  return instance.patch(url, data, mergeConfig(config))
}

const handleDelete = instance => async (url, config = {}) => {
  return instance.delete(url, mergeConfig(config))
}

export default (baseURL = apiUrl) => {
  const instance = applyConverters(axios.create({ baseURL }), {
    preservedKeys: ['accessToken']

  })

  return ({
    get: get(instance),
    post: post(instance),
    put: put(instance),
    patch: patch(instance),
    delete: handleDelete(instance)
  })
}
