import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Paper from 'components/atoms/Paper'
import { Button, SingleSelect } from 'de-suite-me-ui'
import { formatPayloadDate } from 'utils/date'
import Checkbox from 'components/atoms/Checkbox'
import { updateEmployeeRoutine } from 'features/auth/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/atoms/Input'
import { validateEmployeeField, validateEmployeeValues } from 'features/EmployeeProfile/ducks/schema'
import { values as ramdaValues } from 'ramda'
import { getCurrentUser } from 'features/auth/ducks/selectors'

const EmployeeSettings = ({ user }) => {
  const [values, setValues] = useState({})
  const [valid, setValid] = useState({})
  const loggedInUser = useSelector(getCurrentUser)
  const selfUpdate = loggedInUser.id === user.id
  const dispatch = useDispatch()

  const employmentTypeOptions = {
    b2b: {
      label: 'B2B',
      value: 'b2b'
    },
    uop: {
      label: 'UOP',
      value: 'uop'
    },
    uz: {
      label: 'UZ',
      value: 'uz'
    },
    uod: {
      label: 'UOD',
      value: 'uod'
    }
  }

  useEffect(() => {
    validateEmployeeValues(values, setValid)
  }, [values])

  useEffect(() => {
    setValues(user)
  }, [user])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    dispatch(updateEmployeeRoutine({
      ...values,
      selfUpdate
    }))
  }

  useEffect(() => {
    setValues({})
  }, [])

  return (
    <Paper>
      <SectionHeader>
        General settings
      </SectionHeader>
      <FormWrapper>
        <Row>
          <CheckboxWrapper>
            <Checkbox
              value={values.isAdmin}
              name='isAdmin'
              label='Is admin'
              onChange={handleValueChange}
            />
          </CheckboxWrapper>
        </Row>
        <Row>
          <div>
            <Input
              required
              name='firstName'
              label='First name'
              value={values.firstName}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <Input
              required
              name='lastName'
              label='Last name'
              value={values.lastName}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <Input
              name='nickname'
              label='Nickname'
              value={values.nickname}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <Input
              name='companyRole'
              label='Company role'
              value={values.companyRole}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
        </Row>
        <Row>
          <div>
            <Input
              required
              name='email'
              label='Email'
              value={values.email}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <Input
              name='phoneNumber'
              label='Phone number'
              value={values.phoneNumber}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <Input
              name='employmentDate'
              label='Hire date'
              value={formatPayloadDate(values.employmentDate)}
              type='date'
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <SingleSelect
              required
              options={ramdaValues(employmentTypeOptions)}
              label='Employment type'
              value={employmentTypeOptions[values.type]}
              onChange={option => handleValueChange('type', option.value)}
            />
          </div>
        </Row>
      </FormWrapper>
      <FormWrapper>
        <SectionHeader>
          Absences settings
        </SectionHeader>
        <Row>
          <div>
            <Input
              required
              type='number'
              inputProps={{ min: 0 }}
              name='availableHolidayDays'
              label='Days left'
              value={values.availableHolidayDays}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <Input
              required
              type='number'
              inputProps={{ min: 0 }}
              name='annualHolidayDays'
              label='Annual holiday days'
              value={values.annualHolidayDays}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <Input
              required
              type='number'
              inputProps={{ min: 0 }}
              name='holidayDaysFromPrevYear'
              label='Days from prev year'
              value={values.holidayDaysFromPrevYear}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <Input
              required
              type='number'
              inputProps={{ min: 0 }}
              name='availableChildcareDays'
              label='Available childcare days'
              value={values.availableChildcareDays}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
          <div>
            <Input
              required
              type='number'
              inputProps={{ min: 0 }}
              name='annualChildcareDays'
              label='Annual childcare days'
              value={values.annualChildcareDays}
              onChange={handleValueChange}
              validate={validateEmployeeField(values)}
            />
          </div>
        </Row>
        <ButtonWrapper>
          <Button
            color='secondary'
            disabled={!valid}
            onClick={handleSubmit}
          >
            Save changes
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </Paper>
  )
}

export default EmployeeSettings

const SectionHeader = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  margin-bottom: 10px;
  font-weight: bold;
`

const FormWrapper = styled.div`
  margin-bottom: 20px;
`

const Row = styled.div`
  display: flex;
  gap: 10px;

  & > div {
    flex: 1;
  }
`

const CheckboxWrapper = styled.div`
  margin: 10px 0 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`
