import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Paper from 'components/atoms/Paper'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import AddAbsencePanel from 'features/dashboard/components/AddAbsencePanel'
import UserGeneralInfo from 'features/dashboard/components/UserGeneralInfo'
import UserHolidayStats from 'features/dashboard/components/UserHolidayStats'
import { selectTodayAbsences, selectTomorrowAbsences } from 'features/calendar/ducks/selectors'
import { sortAbsencesByType } from 'utils/absences'
import AbsencesList from 'features/dashboard/components/AbsencesList'
import { CalendarIcon } from 'de-suite-me-ui'
import UserActiveAbsences from 'features/dashboard/components/UserActiveAbsences'
import { fetchCalendarDataRoutine } from 'features/calendar/ducks/actions'
import moment from 'moment'
import { mobileCss, WebContent } from 'components/RWD'

const Dashboard = () => {
  const currentUser = useSelector(getCurrentUser)
  const todayAbsences = useSelector(selectTodayAbsences)
  const tomorrowAbsences = useSelector(selectTomorrowAbsences)
  const dispatch = useDispatch()

  const sortedTodayAbsences = useMemo(() => {
    return sortAbsencesByType(todayAbsences)
  }, [todayAbsences])

  const sortedTomorrowAbsences = useMemo(() => {
    return sortAbsencesByType(tomorrowAbsences)
  }, [tomorrowAbsences])

  useEffect(() => {
    dispatch(fetchCalendarDataRoutine({
      year: moment().year(),
      month: moment().format('MM')
    }))
  }, [])

  return (
    <DashboardWrapper>
      <DashboardContentWrapper>
        <DashboardHeader>
          Hello, <span>have a nice day at work️</span>
        </DashboardHeader>
        <ContentWrapper>
          <DashboardContent>
            <Paper>
              <AddAbsencePanel user={currentUser} viewType='dashboard' />
            </Paper>
            <NarrowBox>
              <PanelLabel>
                <CalendarIcon />
                <span>Today</span>
              </PanelLabel>
              <Paper>
                <AbsencesList absences={sortedTodayAbsences} />
              </Paper>
              <PanelLabel>
                <CalendarIcon />
                <span>Tomorrow</span>
              </PanelLabel>
              <Paper>
                <AbsencesList absences={sortedTomorrowAbsences} />
              </Paper>
            </NarrowBox>
          </DashboardContent>
        </ContentWrapper>
      </DashboardContentWrapper>
      <WebContent>
        <DashboardSidePanelWrapper>
          <UserGeneralInfo currentUser={currentUser} />
          <UserHolidayStats currentUser={currentUser} />
          <UserActiveAbsences currentUser={currentUser} />
        </DashboardSidePanelWrapper>
      </WebContent>
    </DashboardWrapper>
  )
}

export default Dashboard

const DashboardWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`

const DashboardHeader = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-weight: 500;
  
  span {
    font-size: 12px;
    font-weight: 300;
  }
`

const ContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  padding-bottom: 30px;
`

const DashboardContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 505px;
`

const NarrowBox = styled.div`
  margin-top: 25px;
  padding: 0 15px;
`

const PanelLabel = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin-bottom: 10px;
  
  span  {
    display: inline-block;
    margin-left: 10px;
  }
`

const DashboardContentWrapper = styled.div`
  box-sizing: border-box;
  padding-right: 300px;
  
  ${mobileCss(`
    padding-right: 0;
  `)}
`

const DashboardSidePanelWrapper = styled.div`
  box-sizing: border-box;
  width: 300px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.11);
  padding: 26px 23px;
  background-color: ${({ theme }) => theme.palette.common.white};
  overflow-y: auto;
`
