import { object, string, number } from 'yup'
import { validateField, validateValues } from 'utils/form'

export const employeeSchema = object().shape({
  type: string()
    .required()
    .oneOf(
      ['b2b', 'uop', 'uz', 'uod'],
      'Wrong employment type'
    ),
  email: string()
    .required('Email is required')
    .email('Wrong email address'),
  firstName: string()
    .required('First name is required')
    .min(3, 'At least 3 letters')
    .matches(/^\D*$/, {
      message: 'Incorrect value'
    }),
  lastName: string()
    .required('Last name is required')
    .min(3, 'At least 3 letters')
    .matches(/^\D*$/, {
      message: 'Incorrect value'
    }),
  nickname: string()
    .nullable(),
  companyRole: string()
    .nullable(),
  phoneNumber: string()
    .matches(/^\d{9}$|^$/, {
      message: 'Wrong phone number'
    })
    .nullable(),
  availableHolidayDays: number().typeError('Value must be a number')
    .required('Field is required')
    .min(0, 'Value must be greater or equal 0'),
  annualHolidayDays: number().typeError('Value must be a number')
    .required('Field is required')
    .min(0, 'Value must be greater or equal 0'),
  holidayDaysFromPrevYear: number().typeError('Value must be a number')
    .required('Field is required')
    .min(0, 'Value must be greater or equal 0'),
  availableChildcareDays: number().typeError('Value must be a number')
    .required('Field is required')
    .min(0, 'Value must be greater or equal 0'),
  annualChildcareDays: number().typeError('Value must be a number')
    .required('Field is required')
    .min(0, 'Value must be greater or equal 0')
})

export const validateEmployeeField = values => validateField(employeeSchema, values)
export const validateEmployeeValues = validateValues(employeeSchema)
