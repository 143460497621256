import React from 'react'
import styled, { css } from 'styled-components'
import { ABSENCE_TYPES } from 'utils/absences'
import { formatDate } from 'utils/date'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import Tooltip from '@material-ui/core/Tooltip'
import { useSelector } from 'react-redux'
import { selectIsAdmin } from 'features/auth/ducks/selectors'
import moment from 'moment'
import { MobileContent, mobileCss, WebContent } from 'components/RWD'
import { pathOr } from 'ramda'

const ProfileAbsenceItem = ({ absence, openModal, actionsVisible }) => {
  const isAdmin = useSelector(selectIsAdmin)
  const canEdit = isAdmin || moment(absence.dateFrom).isSameOrAfter(moment(), 'day')
  const canEditSingleDay = isAdmin || moment(absence.dateTo).isSameOrAfter(moment(), 'day')

  return (
    <>
      <WebContent>
        <AbsenceWrapper>
          <img src={pathOr('', [absence.type, 'icon'], ABSENCE_TYPES)} alt='absence-icon' />
          <AbsenceType>
            {pathOr('', [absence.type, 'label'], ABSENCE_TYPES)}
          </AbsenceType>
          <AbsenceDates>
            {formatDate(absence.dateFrom)} - {formatDate(absence.dateTo)}
          </AbsenceDates>
          <AbsenceWorkingDays>
            {absence.workingDays} {absence.workingDays <= 1 ? 'day' : 'days'}
          </AbsenceWorkingDays>
          <AbsenceComment>
            {absence.comment}
          </AbsenceComment>
          {
            actionsVisible && (
              <AbsenceActions>
                {
                  absence.workingDays > 1 && (
                    <Tooltip title='Remove single day'>
                      <IconWrapper
                        onClick={canEditSingleDay ? openModal('single-day', absence) : () => {}}
                        disabled={!canEditSingleDay}
                        className='single-day'
                      >
                        <EventBusyIcon />
                      </IconWrapper>
                    </Tooltip>
                  )
                }
                <Tooltip title='Edit absence'>
                  <IconWrapper
                    onClick={canEdit ? openModal('edit', absence) : () => {}}
                    disabled={!canEdit}
                    className='edit'
                  >
                    <EditOutlinedIcon />
                  </IconWrapper>
                </Tooltip>
                <Tooltip title='Remove absence'>
                  <IconWrapper
                    onClick={canEdit ? openModal('delete', absence) : () => {}}
                    disabled={!canEdit}
                    className='remove'
                  >
                    <DeleteOutlinedIcon />
                  </IconWrapper>
                </Tooltip>
              </AbsenceActions>
            )
          }
        </AbsenceWrapper>
      </WebContent>
      <MobileContent>
        <MobileAbsenceWrapper>
          <MobileAbsenceDetails>
            <img src={pathOr('', [absence.type, 'icon'], ABSENCE_TYPES)} alt='absence-icon' />
            <div>
              <AbsenceType>
                {pathOr('', [absence.type, 'label'], ABSENCE_TYPES)}
              </AbsenceType>
              <AbsenceDates>
                {formatDate(absence.dateFrom)} - {formatDate(absence.dateTo)}
              </AbsenceDates>
            </div>
          </MobileAbsenceDetails>
          <AbsenceComment>
            {absence.comment}
          </AbsenceComment>
          <MobileAbsenceDetailsSpaceBetween>
            <AbsenceWorkingDays>
              {absence.workingDays} {absence.workingDays <= 1 ? 'day' : 'days'}
            </AbsenceWorkingDays>
            {
              actionsVisible && (
                <AbsenceActions>
                  {
                    absence.workingDays > 1 && (
                      <Tooltip title='Remove single day'>
                        <IconWrapper
                          onClick={canEdit ? openModal('single-day', absence) : () => {}}
                          disabled={!canEdit}
                          className='single-day'
                        >
                          <EventBusyIcon />
                        </IconWrapper>
                      </Tooltip>
                    )
                  }
                  <Tooltip title='Edit absence'>
                    <IconWrapper
                      onClick={canEdit ? openModal('edit', absence) : () => {}}
                      disabled={!canEdit}
                      className='edit'
                    >
                      <EditOutlinedIcon />
                    </IconWrapper>
                  </Tooltip>
                  <Tooltip title='Remove absence'>
                    <IconWrapper
                      onClick={canEdit ? openModal('delete', absence) : () => {}}
                      disabled={!canEdit}
                      className='remove'
                    >
                      <DeleteOutlinedIcon />
                    </IconWrapper>
                  </Tooltip>
                </AbsenceActions>
              )
            }
          </MobileAbsenceDetailsSpaceBetween>
        </MobileAbsenceWrapper>
      </MobileContent>
    </>
  )
}

export default ProfileAbsenceItem

const AbsenceWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.common.alto};
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  transition: all .3s;

  img {
    width: 30px;
    margin-right: 20px;
  }
  
  & > div:not(:last-of-type) {
    padding-right: 15px;
  }
  
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.whisper};
  }
`

const MobileAbsenceWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.common.alto};
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  transition: all .3s;
  flex-direction: column;

  img {
    width: 30px;
    margin-right: 20px;
  }
`

const AbsenceType = styled.div`
  flex: .5;
  
  ${mobileCss(`
    font-weight: bold;
    flex: 1;
  `)}
`

const AbsenceDates = styled.div`
  flex: 1;
`

const AbsenceWorkingDays = styled.div`
  flex: .3;
  
  ${mobileCss(`
    flex: 1;
    padding-left: 63px;
    padding-bottom: 5px;
  `)}
`

const AbsenceComment = styled.div`
  flex: 1;
  
  ${mobileCss(`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 63px;
    margin-top: 5px;
  `)}
`

const AbsenceActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
`

const IconWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
  transition: all .3s;
  border-radius: 50%;
  
  &.single-day {
    & > * {
      color: ${({ theme }) => theme.palette.primary.main};
    }
    &:hover {
      & > * {
        color: ${({ theme }) => theme.palette.primary.dark};
      }
    }
  }
  
  &.edit {
    & > * {
      color: ${({ theme }) => theme.palette.secondary.main};
      
      &:hover {
        color: ${({ theme }) => theme.palette.secondary.dark};
      }
    }
  }
  
  &.remove {
    & > * {
      color: ${({ theme }) => theme.palette.error.main};
      
      &:hover {
        color: ${({ theme }) => theme.palette.error.dark};
      }
    }
  }
  
  ${({ disabled }) => disabled && css`
    & > * {
      color: ${({ theme }) => theme.palette.common.alto} !important;
      cursor: default;
    }
  `}
`

const MobileAbsenceDetails = styled.div`
  display: flex;
  align-items: center;
`

const MobileAbsenceDetailsSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
`
