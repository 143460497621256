export const PATHS = {
  login: '/',
  dashboard: '/dashboard',
  calendar: '/calendar',
  userPanel: '/user-panel',
  employeeProfile: '/employee/:id',
  users: '/users'
}

export const PRIVATE_PATHS = [
  PATHS.dashboard,
  PATHS.calendar,
  PATHS.userPanel,
  PATHS.employeeProfile,
  PATHS.users
]

export const isPrivatePath = pathname => PRIVATE_PATHS.includes(pathname)
