import React from 'react'
import styled from 'styled-components'
import oval from 'assets/oval.svg'
import oval1 from 'assets/oval1.svg'
import oval2 from 'assets/oval2.svg'
import oval3 from 'assets/oval3.svg'
import oval4 from 'assets/oval4.svg'

const AnimatedBackground = () => {
  return (
    <BubblesContainer>
      <img src={oval} className='x1' />
      <img src={oval4} className='x2' />
      <img src={oval2} className='x3' />
      <img src={oval3} className='x4' />
      <img src={oval} className='x5' />
      <img src={oval1} className='x6' />
      <img src={oval2} className='x7' />
      <img src={oval3} className='x8' />
      <img src={oval} className='x9' />
      <img src={oval1} className='x10' />
      <img src={oval2} className='x11' />
      <img src={oval} className='x12' />
      <img src={oval4} className='x13' />
      <img src={oval1} className='x14' />
      <img src={oval2} className='x15' />
      <img src={oval3} className='x16' />
    </BubblesContainer>
  )
}

export default AnimatedBackground

const BubblesContainer = styled.div`
  position: fixed;
  top: 0;
  left: 60px;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  
  img {
    position: absolute;
  }
`
