import { emptyCalendarState } from 'features/calendar/ducks/state'
import { fetchAllHolidaysRoutine, fetchCalendarDataRoutine } from 'features/calendar/ducks/actions'

export default (state = emptyCalendarState, action) => {
  switch (action.type) {
    case fetchCalendarDataRoutine.SUCCESS:
      return {
        ...state,
        users: action.payload
      }
    case fetchAllHolidaysRoutine.SUCCESS:
      return {
        ...state,
        holidays: action.payload
      }
    default:
      return state
  }
}
