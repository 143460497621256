import React from 'react'
import styled from 'styled-components'
import { ActiveAbsence } from 'features/dashboard/components/UserActiveAbsences'
import { Button } from 'de-suite-me-ui'
import { useDispatch } from 'react-redux'
import { deleteAbsenceRoutine } from 'features/calendar/ducks/actions'

const RemoveAbsenceModal = ({ absence, closeModal, viewType, currentUser, clickedDate }) => {
  const dispatch = useDispatch()

  const handleModalClose = () => {
    if (typeof closeModal === 'function') {
      closeModal()
    }
  }

  const handleAbsenceRemove = () => {
    dispatch(deleteAbsenceRoutine({
      userId: currentUser.id,
      absenceId: absence.id,
      viewType,
      clickedDate
    }))
    handleModalClose()
  }

  return (
    <div>
      <AbsenceWrapper>
        <ActiveAbsence absence={absence} />
      </AbsenceWrapper>
      Do you really want to remove this absence?
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button
            color='primary'
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          onClick={handleAbsenceRemove}
          color='secondary'
        >
          Remove absence
        </Button>
      </ButtonsWrapper>
    </div>
  )
}

export default RemoveAbsenceModal

const AbsenceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.palette.common.alto};
  border-radius: 4px;
  padding: 20px 0 10px;
  height: 70px;
  margin-bottom: 30px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  padding-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.palette.common.alto};
`

const CancelButtonWrapper = styled.div`
  margin-right: 15px;
`
