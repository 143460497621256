import React from 'react'
import styled from 'styled-components'
import { Button } from 'de-suite-me-ui'
import { removeEmployeeNoteRoutine } from 'features/auth/ducks/actions'
import { useDispatch } from 'react-redux'

const RemoveNoteModal = ({ note, employeeId, closeModal }) => {
  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(removeEmployeeNoteRoutine({
      id: note.id,
      employeeId
    }))
    typeof closeModal === 'function' && closeModal()
  }

  return (
    <>
      <div>
        Do you really want to remove this note?
      </div>
      <Warning>
        Extra days won't be subtracted from total value!
      </Warning>
      <ButtonWrapper>
        <Button
          color='secondary'
          onClick={handleSubmit}
        >
          Remove note
        </Button>
      </ButtonWrapper>
    </>
  )
}

export default RemoveNoteModal

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`

const Warning = styled.div`
  margin-top: 20px;
  text-align: center;
  padding: 20px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.error.dark};
  color: ${({ theme }) => theme.palette.error.main};
`
