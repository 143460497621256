import { createSelector } from 'reselect'
import { propOr, sort, pipe, pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'helpers/ramda'

export const getUserState = state => state.auth

export const getCurrentUser = createSelector(
  getUserState,
  propOr({}, 'user')
)

export const selectUserLogs = createSelector(
  getUserState,
  propOr([], 'logs')
)

export const selectIsAdmin = createSelector(
  getUserState,
  pathOr(false, ['user', 'isAdmin'])
)

export const selectSelectedEmployee = createSelector(
  getUserState,
  propOr({}, 'employee')
)

export const selectEmployeeLogs = createSelector(
  getUserState,
  propOr({}, 'logs')
)

export const selectAllEmployees = createSelector(
  getUserState,
  pipe(
    propOr([], 'allEmployees'),
    sort((a, b) => a.lastName.localeCompare(b.lastName))
  )
)

export const selectIsFetchingUser = createSelector(
  getUserState,
  propOr(false, 'isFetchingUser')
)

export const getUserId = createSelector(
  getCurrentUser,
  propOr(null, 'id')
)

export const isAuthenticated = createSelector(
  getUserId,
  isNotNilOrEmpty
)
