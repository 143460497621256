import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteEmployeeRoutine, fetchAllEmployeesRoutine } from 'features/auth/ducks/actions'
import { selectAllEmployees } from 'features/auth/ducks/selectors'
import Paper from 'components/atoms/Paper'
import styled, { css } from 'styled-components'
import { isNotNilOrEmpty } from 'helpers/ramda'
import { isEmpty, propOr } from 'ramda'
import { redirect } from 'utils/router'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import Modal from 'components/atoms/Modal'
import { Button } from 'de-suite-me-ui'
import Checkbox from 'components/atoms/Checkbox'
import BulkAddNoteModal from 'features/userProfile/components/BulkAddNoteModal'

const UsersList = () => {
  const dispatch = useDispatch()
  const employees = useSelector(selectAllEmployees)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState({})
  const [selectedUsers, setSelectedUsers] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [noteModalOpen, setNoteModalOpen] = useState(false)

  const handleNoteModalOpen = () => setNoteModalOpen(true)
  const handleNoteModalClose = () => setNoteModalOpen(false)

  const toggleAllSelect = (_, value) => {
    if (value) {
      setSelectedUsers(employees.map(e => e.id))
      setAllSelected(true)
    } else {
      setSelectedUsers([])
      setAllSelected(false)
    }
  }

  const handleUserSelect = id => () => {
    setSelectedUsers(prev => prev.includes(id) ? selectedUsers.filter(userId => userId !== id) : [...prev, id])
  }

  useEffect(() => {
    dispatch(fetchAllEmployeesRoutine())
  }, [])

  const handleModalOpen = employee => e => {
    e.stopPropagation()
    setSelectedEmployee(employee)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setSelectedEmployee({})
    setModalOpen(false)
  }

  const handleDeleteEmployee = () => {
    dispatch(deleteEmployeeRoutine(selectedEmployee.id))
    handleModalClose()
  }

  return (
    <UsersListWrapper>
      <Paper>
        <TableHeader>
          <CheckboxColumn>
            <Checkbox value={allSelected} onChange={toggleAllSelect} />
          </CheckboxColumn>
          <UserName>
            <AddNote
              disabled={isEmpty(selectedUsers)}
              onClick={isEmpty(selectedUsers) ? () => {} : handleNoteModalOpen}
            >
              + Add a bulk note
            </AddNote>
          </UserName>
          <HeaderCell>Type</HeaderCell>
          <HeaderCell>Available</HeaderCell>
          <HeaderCell>Annual</HeaderCell>
          <HeaderCell>Prev year</HeaderCell>
          <HeaderCell>Childcare</HeaderCell>
          <HeaderCell>Active</HeaderCell>
          <HeaderCell>Admin</HeaderCell>
          <IconWrapper />
        </TableHeader>
        {
          isNotNilOrEmpty(employees) && employees.map(employee => {
            const propOrNot = propName => propOr('---', propName, employee)
            return (
              <UserItem
                key={employee.id}
                onClick={() => redirect(`/employee/${employee.id}`, 'admin')}
              >
                <CheckboxColumn onClick={e => e.stopPropagation()}>
                  <Checkbox
                    value={selectedUsers.includes(employee.id)}
                    onChange={handleUserSelect(employee.id)}
                  />
                </CheckboxColumn>
                <UserName>
                  <img src={employee.avatar} alt='user-avatar' />
                  {employee.fullName}
                </UserName>
                <Cell>{propOrNot('type')}</Cell>
                <Cell>{propOrNot('availableHolidayDays')}</Cell>
                <Cell>{propOrNot('annualHolidayDays')}</Cell>
                <Cell>{propOrNot('holidayDaysFromPrevYear')}</Cell>
                <Cell>{propOrNot('availableChildcareDays')}</Cell>
                <Cell>
                  {
                    propOrNot('activatedAt') !== '---'
                      ? <Checked>✓</Checked>
                      : <Unchecked>X</Unchecked>
                  }
                </Cell>
                <Cell>
                  {
                    propOrNot('isAdmin') === true
                      ? <Checked>✓</Checked>
                      : <Unchecked>X</Unchecked>
                  }
                </Cell>
                <IconWrapper onClick={handleModalOpen(employee)}>
                  <DeleteOutlinedIcon />
                </IconWrapper>
              </UserItem>
            )
          })
        }
      </Paper>
      <Modal
        open={modalOpen}
        title={selectedEmployee.fullName}
        onClose={handleModalClose}
      >
        Do you really want to remove this employee?
        <ButtonsWrapper>
          <CancelButtonWrapper>
            <Button
              color='primary'
              onClick={handleModalClose}
            >
              Cancel
            </Button>
          </CancelButtonWrapper>
          <Button
            onClick={handleDeleteEmployee}
            color='secondary'
          >
            Remove
          </Button>
        </ButtonsWrapper>
      </Modal>
      <Modal
        open={noteModalOpen}
        title='Add note'
        onClose={handleNoteModalClose}
      >
        <BulkAddNoteModal
          selectedUsers={selectedUsers}
          callback={() => {
            setSelectedUsers([])
            setAllSelected(false)
            handleNoteModalClose()
          }}
        />
      </Modal>
    </UsersListWrapper>
  )
}

export default UsersList

const UsersListWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`

const UserItem = styled.div`
  box-sizing: border-box;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.whisper};
  }
`

const UserName = styled.div`
  width: 230px;
  display: flex;
  align-items: center;
  
  img {
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 50%;
    width: 30px;
    margin-right: 15px;
  }
`

const CheckboxColumn = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
  margin-top: -17px;
`

const Cell = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.palette.common.dustyGray};
`

const HeaderCell = styled.div`
  flex: 1;
`

const TableHeader = styled.div`
  box-sizing: border-box;
  padding: 15px;
  position: sticky;
  top: -40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.dustyGray};
  margin-bottom: 15px;
  z-index: 2;
`

const IconWrapper = styled.div`
  width: 30px;
  padding: 5px;
  cursor: pointer;
  
  & > * {
    color: ${({ theme }) => theme.palette.error.main};
  }
  &:hover {
    & > * {
      color: ${({ theme }) => theme.palette.error.dark};
    }
  }
`

const Checked = styled.div`
  color: ${({ theme }) => theme.palette.success.main};
`

const Unchecked = styled.div`
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  padding-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.palette.common.alto};
`

const CancelButtonWrapper = styled.div`
  margin-right: 15px;
`

const AddNote = styled.div`
  cursor: pointer;
  margin-top: -3px;
  transition: all .3s;
  color: ${({ theme }) => theme.palette.secondary.main};
  
  &:hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
  
  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.palette.common.dustyGray} !important;
    cursor: default;
  `}
`
