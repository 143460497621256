import React, { useMemo, useState } from 'react'
import Paper from 'components/atoms/Paper'
import styled from 'styled-components'
import moment from 'moment'
import { isNotEmpty } from 'helpers/ramda'
import ProfileAbsenceItem from 'features/userProfile/components/ProfileAbsenceItem'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Modal from 'components/atoms/Modal'
import RemoveSingleDayModal from 'features/userProfile/components/RemoveSingleDayModal'
import RemoveAbsenceModal from 'features/userProfile/components/RemoveAbsenceModal'
import { getCurrentUser, selectIsAdmin } from 'features/auth/ducks/selectors'
import { useSelector } from 'react-redux'
import { mobileCss, WebContent } from 'components/RWD'
import ModalContent from 'features/calendar/components/ModalContent'
import { getMonthsInRange } from 'utils/calendar'

const UserProfileAbsences = ({ currentUser }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedModalType, setSelectedModalType] = useState(null)
  const [selectedAbsence, setSelectedAbsence] = useState({})
  const loggedInUser = useSelector(getCurrentUser)
  const isAdmin = useSelector(selectIsAdmin)
  const isLoggedInUser = loggedInUser.id === currentUser.id
  const actionsVisible = isLoggedInUser || isAdmin

  const [date, setDate] = useState({
    year: moment().year(),
    month: Number(moment().format('MM'))
  })

  const handleDataChange = direction => () => {
    switch (direction) {
      case 'prev':
        if (date.month === 1) {
          setDate({ year: Number(date.year - 1), month: 12 })
        } else {
          setDate({ ...date, month: Number(date.month) - 1 })
        }
        break
      case 'next':
        if (date.month === 12) {
          setDate({ year: Number(date.year) + 1, month: 1 })
        } else {
          setDate({ ...date, month: Number(date.month) + 1 })
        }
        break
      default:
        break
    }
  }

  const absences = useMemo(() => {
    if (currentUser.absences) {
      const filteredAbsences = currentUser.absences.filter(absence => {
        const months = getMonthsInRange(absence.dateFrom, absence.dateTo)
        return months.some(
          item => item.month === date.month && item.year === date.year
        )
      })
      return filteredAbsences.sort((a, b) =>
        a.dateFrom.localeCompare(b.dateFrom)
      )
    } else {
      return []
    }
  }, [date, currentUser])

  const handleModalOpen = (type, absence) => () => {
    setSelectedModalType(type)
    setSelectedAbsence(absence)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
    setSelectedModalType(null)
    setSelectedAbsence({})
  }

  const modalContent = useMemo(() => {
    switch (selectedModalType) {
      case 'single-day':
        return (
          <RemoveSingleDayModal
            closeModal={handleModalClose}
            absence={selectedAbsence}
            viewType={isLoggedInUser ? 'dashboard' : ''}
            currentUser={currentUser}
            clickedDate={date}
          />
        )
      case 'delete':
        return (
          <RemoveAbsenceModal
            closeModal={handleModalClose}
            absence={selectedAbsence}
            viewType={isLoggedInUser ? 'dashboard' : ''}
            currentUser={currentUser}
            clickedDate={date}
          />
        )
      case 'edit':
        return (
          <ModalContent
            onClose={handleModalClose}
            type='edit'
            data={{
              user: currentUser,
              absence: selectedAbsence,
              viewType: isLoggedInUser ? 'dashboard' : ''
            }}
            profileView
          />
        )
      case 'add':
        return (
          <ModalContent
            onClose={handleModalClose}
            type='add'
            data={{
              user: currentUser,
              viewType: isLoggedInUser ? 'dashboard' : 'dsdsdsi'
            }}
            profileView
          />
        )
      default:
        return <div />
    }
  }, [selectedModalType])

  const modalTitle = useMemo(() => {
    switch (selectedModalType) {
      case 'single-day':
        return 'Remove single day'
      case 'delete':
        return 'Remove absence'
      case 'edit':
        return 'Edit absence'
      case 'add':
        return 'Add absencesas'
      default:
        return ''
    }
  }, [selectedModalType])

  return (
    <Paper>
      <SectionHeaderWrapper>
        <SectionHeaderText>Absences list</SectionHeaderText>
        <ActionsWrapper>
          <WebContent>
            {actionsVisible && (
              <AddAbsenceButton onClick={handleModalOpen('add', {})}>
                + Add absence
              </AddAbsenceButton>
            )}
          </WebContent>
          <MonthSelectionWrapper>
            <ChevronLeft onClick={handleDataChange('prev')} />
            {/* moment iterates months from 0; get month as moment object, subtract one month and then format it */}
            {moment().month(date.month).subtract(1, 'month').format('MMMM')}{' '}
            {date.year}
            <ChevronRight onClick={handleDataChange('next')} />
          </MonthSelectionWrapper>
        </ActionsWrapper>
      </SectionHeaderWrapper>
      <AbsencesList>
        {isNotEmpty(absences) ? (
          absences.map(absence => {
            return (
              <ProfileAbsenceItem
                actionsVisible={actionsVisible}
                openModal={handleModalOpen}
                absence={absence}
                key={absence.id}
              />
            )
          })
        ) : (
          <EmptyState>There are no absences for this month</EmptyState>
        )}
      </AbsencesList>
      <Modal open={modalOpen} onClose={handleModalClose} title={modalTitle}>
        {modalContent}
      </Modal>
    </Paper>
  )
}

export default UserProfileAbsences

const SectionHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SectionHeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.dark};
`

const AbsencesList = styled.div`
  margin-top: 20px;
`

const EmptyState = styled.div`
  width: 100%;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.common.dustyGray};
`

export const ChevronLeft = styled(ChevronLeftIcon)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`

export const ChevronRight = styled(ChevronRightIcon)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.primary.main};
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`

const MonthSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  color: ${({ theme }) => theme.palette.common.dustyGray};

  ${mobileCss(`
    width: 160px;
  `)}
`

const AddAbsenceButton = styled.div`
  color: ${({ theme }) => theme.palette.secondary.main};
  transition: all 0.3s;
  cursor: pointer;
  margin-right: 40px;

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`
