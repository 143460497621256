import { combineReducers } from 'redux'
import { routinePromiseWatcherSaga } from 'redux-saga-routines'
import { all, fork } from '@redux-saga/core/effects'

// reducers
import authReducer from 'features/auth/ducks/reducer'
import loaderReducer from 'ducks/loaderReducer'
import calendarReducer from 'features/calendar/ducks/reducer'

// sagas
import { authSagas } from 'features/auth/ducks/actions'
import { calendarSagas } from 'features/calendar/ducks/actions'

// reducer
const appReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  calendar: calendarReducer
})

export default appReducer

export function * rootSaga () {
  yield all([
    ...authSagas,
    ...calendarSagas,
    fork(routinePromiseWatcherSaga)
  ])
}
