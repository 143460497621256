import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { ActiveAbsence } from 'features/dashboard/components/UserActiveAbsences'
import { isNotNilOrEmpty } from 'helpers/ramda'
import moment from 'moment'
import { deleteSingleDayFromAbsenceRoutine } from 'features/calendar/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'de-suite-me-ui'
import { isNil } from 'ramda'
import { selectIsAdmin } from 'features/auth/ducks/selectors'
import { selectHolidays } from 'features/calendar/ducks/selectors'
import { formatDate } from 'utils/date'
import { mobileCss } from 'components/RWD'

const RemoveSingleDayModal = ({ absence, closeModal, viewType, currentUser, clickedDate }) => {
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedDayIndex, setSelectedDayIndex] = useState(null)
  const dispatch = useDispatch()
  const holidays = useSelector(selectHolidays)
  const isAdmin = useSelector(selectIsAdmin)

  const absenceDays = useMemo(() => {
    let currentDate = moment(absence.dateFrom).clone()
    const days = []
    let index = 0

    const holidaysDates = holidays.map(holiday => formatDate(holiday.date))

    while (currentDate.isSameOrBefore(absence.dateTo, 'days')) {
      /* check weekends and holidays */
      if (![6, 7].includes(currentDate.isoWeekday()) && !holidaysDates.includes(formatDate(currentDate))) {
        days.push({
          index,
          dayName: currentDate.format('dddd'),
          day: currentDate.format('D'),
          month: currentDate.format('MMMM'),
          fullDate: `${currentDate.year()}-${currentDate.format('MM')}-${currentDate.format('DD')}`
        })
      }
      currentDate = moment(currentDate).add(1, 'day')
      index++
    }
    return isAdmin
      ? days
      : days.filter(day => moment(day.fullDate).isSameOrAfter(moment(), 'day'))
  }, [absence])

  const handleSelectDay = day => () => {
    setSelectedDay(day)
    setSelectedDayIndex(day.index)
  }

  const handleModalClose = () => {
    if (typeof closeModal === 'function') {
      closeModal()
    }
  }

  const handleSubmit = () => {
    dispatch(deleteSingleDayFromAbsenceRoutine({
      absenceId: absence.id,
      date: selectedDay.fullDate,
      userId: currentUser.id,
      viewType,
      clickedDate
    }))
    handleModalClose()
  }

  return isNotNilOrEmpty(absence) && (
    <div>
      <AbsenceWrapper>
        <ActiveAbsence absence={absence} />
      </AbsenceWrapper>
      <SectionHeaderText>Select one day</SectionHeaderText>
      <DaysWrapper>
        {
          isNotNilOrEmpty(absenceDays) && absenceDays.map((day, index) => (
            <SingleDayWrapper
              active={selectedDayIndex === day.index}
              key={index}
              onClick={handleSelectDay(day)}
            >
              <DayName>{day.dayName}</DayName>
              <Day>{day.day}</Day>
              <Month>{day.month}</Month>
            </SingleDayWrapper>
          ))
        }
      </DaysWrapper>
      <ButtonsWrapper>
        <CancelButtonWrapper>
          <Button
            color='primary'
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        </CancelButtonWrapper>
        <Button
          onClick={handleSubmit}
          color='secondary'
          disabled={isNil(selectedDay)}
        >
          Remove this day
        </Button>
      </ButtonsWrapper>
    </div>
  )
}

export default RemoveSingleDayModal

const AbsenceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.palette.common.alto};
  border-radius: 4px;
  padding: 20px 0 10px;
  height: 70px;
`

const SectionHeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.dark};
  margin: 20px 0 10px;
`

const DaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 90px);
  justify-content: space-between;
  grid-gap: 10px;
   
  ${mobileCss(`
    grid-template-columns: repeat(auto-fill, calc(50% - 5px));
  `)}
`

const SingleDayWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.common.alto};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;
  transition: all .3s;
  width: 90px;
  color: ${({ theme }) => theme.palette.common.dustyGray};
  
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.transparent};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
  
  ${({ active, theme }) => active && css`
    background-color: ${theme.palette.primary.transparent};
    border-color: ${theme.palette.primary.dark};
    color: ${theme.palette.primary.dark};
    
    &:hover {
      background-color: ${theme.palette.primary.transparent};
      border-color: ${theme.palette.primary.dark};
      color: ${theme.palette.primary.dark};
    }
  `}
  
  ${mobileCss(`
    width: 100%;
  `)}
`

const DayName = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`

const Day = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`

const Month = styled.div`
  font-size: 16px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  padding-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.palette.common.alto};
`

const CancelButtonWrapper = styled.div`
  margin-right: 15px;
`
