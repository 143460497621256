import React from 'react'
import styled from 'styled-components'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import UserHolidayStats from 'features/dashboard/components/UserHolidayStats'
import UserGeneralInfo from 'features/dashboard/components/UserGeneralInfo'
import Paper from 'components/atoms/Paper'
import UserSettings from 'features/userProfile/components/UserSettings'
import UserProfileAbsences from 'features/userProfile/components/UserProfileAbsences'
import { mobileCss, WebContent } from 'components/RWD'
import { fetchCurrentUserRoutine } from 'features/auth/ducks/actions'
import { propOr } from 'ramda'
import { useEffect } from 'react'

const UserProfile = () => {
  const currentUser = useSelector(getCurrentUser)
  const userId = propOr('', 'id', currentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCurrentUserRoutine(userId))
  }, [])

  return (
    <UserProfileWrapper>
      <UserDetailsWrapper>
        <Paper className='paper'>
          <UserGeneralInfo currentUser={currentUser} />
          <UserHolidayStats currentUser={currentUser} />
        </Paper>
        <WebContent>
          <Paper className='paper'>
            <UserSettings currentUser={currentUser} />
          </Paper>
        </WebContent>
      </UserDetailsWrapper>
      <AbsencesListWrapper>
        <UserProfileAbsences currentUser={currentUser} />
      </AbsencesListWrapper>
    </UserProfileWrapper>
  )
}

export default UserProfile

const UserProfileWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`

const UserDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  
  .paper {
    min-height: 375px !important;
  }
  
  & > div:first-of-type {
    margin-right: 20px;
  }
  
  ${mobileCss(`
    & > div:first-of-type {
      margin-right: unset;
    }  
  `)}
`

const AbsencesListWrapper = styled.div`

`
