import React from 'react'
import { Dialog as MaterialModal } from '@material-ui/core'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import { Button } from 'de-suite-me-ui'
import { mobileCss } from 'components/RWD'

const Modal = props => {
  const {
    title,
    titleNoHover,
    children,
    withSubmit,
    withCancel,
    submitButtonText,
    cancelButtonText,
    onCancel,
    onSubmit,
    disabled
  } = props

  return (
    <MaterialModal {...props}>
      <StyledModal>
        <ModalHeader>
          <ModalTitle>{titleNoHover || title}</ModalTitle>
          <StyledCloseIcon onClick={props.onClose} />
        </ModalHeader>
        {children}
        {
          (withSubmit || withCancel) && (
            <ButtonsWrapper>
              {
                withCancel && (
                  <Button variant='outlined' onClick={onCancel}>
                    {cancelButtonText}
                  </Button>
                )
              }
              {
                withSubmit && (
                  <SubmitButton color='primary' onClick={onSubmit} disabled={disabled}>
                    {submitButtonText}
                  </SubmitButton>
                )
              }
            </ButtonsWrapper>
          )
        }
      </StyledModal>
    </MaterialModal>
  )
}

Modal.defaultProps = {
  submitButtonText: 'Submit',
  cancelButtonText: 'Cancel',
  onCancel: () => {},
  onSubmit: () => {}
}

export default Modal

const StyledModal = styled.div`
  box-sizing: border-box;
  padding: 20px;
  min-width: 435px; 
  background-color: ${({ theme }) => theme.palette.common.white};
  
  ${mobileCss(`
    min-width: unset;
  `)}
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.alto};
`

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  opacity: .5;
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 14px !important;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.dustyGray};
  
  &:hover {
    color: ${({ theme }) => theme.palette.common.black};
  }
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.palette.common.alto};
  display: flex;
  justify-content: flex-end;
`

const SubmitButton = styled(Button)`
  margin-left: 15px !important;
`
