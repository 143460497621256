import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginUserRoutine } from 'ducks/user'
import { propOr } from 'ramda'
import GoogleLogin from 'react-google-login'
import { Button } from 'de-suite-me-ui'
import { selectLoginLoader } from 'ducks/loaderSelector'

const GoogleLoginButton = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectLoginLoader)

  const loginUser = useCallback(
    e => dispatch(loginUserRoutine({
      accessToken: propOr('', 'accessToken', e)
    })), [dispatch])

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      onSuccess={loginUser}
      onFailure={e => console.warn('failure', e)}
      render={renderProps => (
        <Button
          {...renderProps}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Enter'}
        </Button>
      )}
    />
  )
}

export default GoogleLoginButton
