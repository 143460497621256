import { createRoutine } from 'redux-saga-routines'
import { takeLatest, call, fork, put } from 'redux-saga/effects'
import * as userService from 'services/UserService'
import storageService from 'services/StorageService'
import { dissoc, pathOr } from 'ramda'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'
import { redirect } from 'utils/router'
import { isPrivatePath, PATHS } from 'utils/paths'
import * as EmployeesService from 'services/EmployeesService'
import { extractMultipleMessagesFromErrorResponse } from 'utils/errors'
import { toast } from 'react-toastify'

export const loginUserRoutine = createRoutine('LOGIN_USER')
export const logoutUserRoutine = createRoutine('LOGOUT_USER')
export const fetchAuthUserRoutine = createRoutine('FETCH_AUTH_USER')
export const fetchEmployeeRoutine = createRoutine('FETCH_EMPLOYEE')
export const fetchEmployeeLogsRoutine = createRoutine('FETCH_EMPLOYEE_LOGS')
export const fetchCurrentUserRoutine = createRoutine('FETCH_CURRENT_USER')
export const updateUserProfileRoutine = createRoutine('UPDATE_USER')
export const updateEmployeeRoutine = createRoutine('UPDATE_EMPLOYEE')
export const fetchAllEmployeesRoutine = createRoutine('FETCH_EMPLOYEES')
export const deleteEmployeeRoutine = createRoutine('REMOVE_EMPLOYEE')
export const activateUserRoutine = createRoutine('ACTIVATE_USER')
export const addEmployeeNoteRoutine = createRoutine('ADD_NOTE')
export const editEmployeeNoteRoutine = createRoutine('EDIT_NOTE')
export const removeEmployeeNoteRoutine = createRoutine('REMOVE_NOTE')

function * loginUser ({ payload }) {
  yield put(loginUserRoutine.request())
  try {
    const result = yield call(userService.loginUser, payload)
    storageService.set(LOCAL_STORAGE_KEYS.token, pathOr(null, ['headers', 'authorization'], result))
    storageService.set(LOCAL_STORAGE_KEYS.currentUserId, pathOr(null, ['data', 'data', 'id'], result))
    yield put(loginUserRoutine.success(pathOr({}, ['data', 'data'], result)))
    redirect(PATHS.dashboard)
  } catch (e) {
    toast.error(extractMultipleMessagesFromErrorResponse(e))
    yield put(loginUserRoutine.failure(e))
    console.error(e)
  }
}

function * logoutUser () {
  yield put(logoutUserRoutine.request())
  try {
    yield call(userService.logoutUser)
    yield put(logoutUserRoutine.success())
    storageService.remove(LOCAL_STORAGE_KEYS.token)
    redirect(PATHS.login)
  } catch (e) {
    console.error(e)
  }
}

function * fetchAuthUser ({ payload }) {
  yield put(fetchAuthUserRoutine.request())
  try {
    const result = yield call(EmployeesService.fetchEmployee, payload)
    yield put(fetchAuthUserRoutine.success(pathOr({}, ['data', 'data'], result)))
  } catch (e) {
    storageService.remove(LOCAL_STORAGE_KEYS.token)
    storageService.remove(LOCAL_STORAGE_KEYS.currentUserId)
    const pathname = pathOr('/', ['location', 'pathname'], window)
    isPrivatePath(pathname) && redirect(PATHS.login)
    yield put(fetchAuthUserRoutine.failure(e))
    console.error(e)
  }
}

function * fetchEmployeeLogs ({ payload }) {
  yield put(fetchEmployeeLogsRoutine.request())
  try {
    const { data } = yield call(EmployeesService.fetchEmployeeLogs, payload)
    yield put(fetchEmployeeLogsRoutine.success(data.data))
  } catch (e) {
    yield put(fetchEmployeeLogsRoutine.failure(e))
    console.error(e)
  }
}

function * activateUser ({ payload }) {
  yield put(activateUserRoutine.request())
  try {
    yield call(EmployeesService.activateEmployee, payload)
    yield put(activateUserRoutine.success())
    yield put(fetchEmployeeRoutine(payload))
    toast.success('User has been activated')
  } catch (e) {
    toast.error(extractMultipleMessagesFromErrorResponse(e))
    yield put(activateUserRoutine.failure(e))
    console.error(e)
  }
}

function * addEmployeeNote ({ payload }) {
  yield put(addEmployeeNoteRoutine.request())
  try {
    yield call(EmployeesService.addEmployeeNote, dissoc('type', payload))
    yield put(addEmployeeNoteRoutine.success())
    if (payload.type === 'employee') {
      yield put(fetchEmployeeRoutine(payload.employeesIds[0]))
    } else {
      yield put(fetchAllEmployeesRoutine())
    }
    toast.success('Note has been added')
  } catch (e) {
    toast.error(extractMultipleMessagesFromErrorResponse(e))
    yield put(addEmployeeNoteRoutine.failure(e))
    console.error(e)
  }
}

function * editEmployeeNote ({ payload }) {
  yield put(editEmployeeNoteRoutine.request())
  try {
    yield call(EmployeesService.updateEmployeeNote, dissoc('employeeId', payload))
    yield put(editEmployeeNoteRoutine.success())
    yield put(fetchEmployeeRoutine(payload.employeeId))
    toast.success('Note has been updated')
  } catch (e) {
    toast.error(extractMultipleMessagesFromErrorResponse(e))
    yield put(editEmployeeNoteRoutine.failure(e))
    console.error(e)
  }
}

function * removeEmployeeNote ({ payload }) {
  yield put(removeEmployeeNoteRoutine.request())
  try {
    yield call(EmployeesService.deleteEmployeeNote, dissoc('employeeId', payload))
    yield put(removeEmployeeNoteRoutine.success())
    yield put(fetchEmployeeRoutine(payload.employeeId))
    toast.success('Note has been removed')
  } catch (e) {
    toast.error(extractMultipleMessagesFromErrorResponse(e))
    yield put(removeEmployeeNoteRoutine.failure(e))
    console.error(e)
  }
}

function * fetchCurrentUser ({ payload }) {
  yield put(fetchCurrentUserRoutine.request())
  try {
    const { data } = yield call(EmployeesService.fetchEmployee, payload)
    yield put(fetchCurrentUserRoutine.success(data.data))
  } catch (err) {
    toast.error(extractMultipleMessagesFromErrorResponse(err))
    yield put(fetchCurrentUserRoutine.failure(err))
    console.error(err)
  }
}

function * fetchEmployee ({ payload }) {
  yield put(fetchEmployeeRoutine.request())
  try {
    const { data } = yield call(EmployeesService.fetchEmployee, payload)
    yield put(fetchEmployeeRoutine.success(data.data))
  } catch (err) {
    toast.error(extractMultipleMessagesFromErrorResponse(err))
    yield put(fetchEmployeeRoutine.failure(err))
    console.error(err)
  }
}

function * fetchAllEmployees () {
  yield put(fetchAllEmployeesRoutine.request())
  try {
    const { data } = yield call(EmployeesService.fetchAllEmployees)
    yield put(fetchAllEmployeesRoutine.success(data.data))
  } catch (e) {
    yield put(fetchAllEmployeesRoutine.failure(e))
  }
}

function * updateUserProfile ({ payload }) {
  yield put(updateUserProfileRoutine.request())
  try {
    const { data } = yield call(EmployeesService.updateEmployee, payload)
    yield put(updateUserProfileRoutine.success(data.data))
    toast.success('Your profile has been updated')
  } catch (err) {
    yield put(updateUserProfileRoutine.failure(err))
    toast.error(extractMultipleMessagesFromErrorResponse(err))
    console.error(err)
  }
}

function * updateEmployee ({ payload }) {
  const { selfUpdate, id } = payload
  yield put(updateEmployeeRoutine.request())
  try {
    yield call(EmployeesService.updateEmployee, dissoc('selfUpdate', payload))
    yield put(updateEmployeeRoutine.success())
    if (selfUpdate) {
      yield put(fetchCurrentUserRoutine(id))
    }
    toast.success('Employee has been updated')
  } catch (err) {
    yield put(updateEmployeeRoutine.failure(err))
    toast.error(extractMultipleMessagesFromErrorResponse(err))
    console.error(err)
  }
}

function * deleteEmployee ({ payload }) {
  yield put(deleteEmployeeRoutine.request())
  try {
    yield call(EmployeesService.deleteEmployee, payload)
    yield put(deleteEmployeeRoutine.success(payload))
    yield put(fetchAllEmployeesRoutine())
    toast.success('Employee has been removed')
  } catch (err) {
    yield put(deleteEmployeeRoutine.failure(err))
    toast.error(extractMultipleMessagesFromErrorResponse(err))
    console.error(err)
  }
}

// WATCHERS
function * loginUserWatcher () {
  yield takeLatest(loginUserRoutine.TRIGGER, loginUser)
}

function * logoutUserWatcher () {
  yield takeLatest(logoutUserRoutine, logoutUser)
}

function * fetchAuthUserWatcher () {
  yield takeLatest(fetchAuthUserRoutine, fetchAuthUser)
}

function * fetchEmployeeWatcher () {
  yield takeLatest(fetchEmployeeRoutine, fetchEmployee)
}

function * fetchCurrentUserWatcher () {
  yield takeLatest(fetchCurrentUserRoutine, fetchCurrentUser)
}

function * updateUserProfileWatcher () {
  yield takeLatest(updateUserProfileRoutine, updateUserProfile)
}

function * updateEmployeeWatcher () {
  yield takeLatest(updateEmployeeRoutine, updateEmployee)
}

function * fetchAllEmployeesWatcher () {
  yield takeLatest(fetchAllEmployeesRoutine, fetchAllEmployees)
}

function * deleteEmployeeWatcher () {
  yield takeLatest(deleteEmployeeRoutine, deleteEmployee)
}

function * activateUserWatcher () {
  yield takeLatest(activateUserRoutine, activateUser)
}

function * addEmployeeNoteWatcher () {
  yield takeLatest(addEmployeeNoteRoutine, addEmployeeNote)
}

function * editEmployeeNoteWatcher () {
  yield takeLatest(editEmployeeNoteRoutine, editEmployeeNote)
}

function * removeEmployeeNoteWatcher () {
  yield takeLatest(removeEmployeeNoteRoutine, removeEmployeeNote)
}

function * fetchEmployeeLogsWatcher () {
  yield takeLatest(fetchEmployeeLogsRoutine, fetchEmployeeLogs)
}

// SAGAS
export const authSagas = [
  fork(loginUserWatcher),
  fork(logoutUserWatcher),
  fork(fetchAuthUserWatcher),
  fork(updateUserProfileWatcher),
  fork(updateEmployeeWatcher),
  fork(fetchEmployeeWatcher),
  fork(fetchAllEmployeesWatcher),
  fork(fetchCurrentUserWatcher),
  fork(activateUserWatcher),
  fork(addEmployeeNoteWatcher),
  fork(editEmployeeNoteWatcher),
  fork(removeEmployeeNoteWatcher),
  fork(deleteEmployeeWatcher),
  fork(fetchEmployeeLogsWatcher)
]
