import {
  fetchEmployeeRoutine,
  loginUserRoutine,
  logoutUserRoutine,
  updateUserProfileRoutine,
  fetchCurrentUserRoutine,
  fetchAllEmployeesRoutine,
  deleteEmployeeRoutine,
  updateEmployeeRoutine
} from 'features/auth/ducks/actions'
import {
  addAbsenceRoutine,
  deleteAbsenceRoutine,
  deleteSingleDayFromAbsenceRoutine,
  fetchCalendarDataRoutine,
  updateAbsenceRoutine
} from 'features/calendar/ducks/actions'

export const emptyLoadingState = {
  isLoggingIn: false,
  isFetchingCalendarData: false,
  isAddingAbsence: false,
  isUpdatingUser: false,
  isRemovingAbsence: false,
  isFetchingEmployee: false,
  isFetchingCurrentUser: false,
  isFetchingAllEmployees: false,
  isUpdatingEmployee: false,
  isUpdatingAbsence: false,
  isRemovingSingleDay: false
}

export default (state = emptyLoadingState, action) => {
  switch (action.type) {
    case loginUserRoutine.REQUEST:
    case logoutUserRoutine.REQUEST:
      return {
        ...state,
        isLoggingIn: true
      }
    case addAbsenceRoutine.REQUEST:
      return {
        ...state,
        isAddingAbsence: true
      }
    case fetchCalendarDataRoutine.REQUEST:
      return {
        ...state,
        isFetchingCalendarData: !action.payload
      }
    case updateUserProfileRoutine.REQUEST:
      return {
        ...state,
        isUpdatingUser: true
      }
    case loginUserRoutine.SUCCESS:
    case logoutUserRoutine.SUCCESS:
    case loginUserRoutine.FAILURE:
    case logoutUserRoutine.FAILURE:
      return {
        ...state,
        isLoggingIn: false
      }
    case fetchCalendarDataRoutine.SUCCESS:
    case fetchCalendarDataRoutine.FAILURE:
      return {
        ...state,
        isFetchingCalendarData: false
      }
    case addAbsenceRoutine.SUCCESS:
    case addAbsenceRoutine.FAILURE:
      return {
        ...state,
        isAddingAbsence: false
      }
    case updateUserProfileRoutine.SUCCESS:
    case updateUserProfileRoutine.FAILURE:
      return {
        ...state,
        isUpdatingUser: false
      }
    case deleteAbsenceRoutine.REQUEST:
      return {
        ...state,
        isRemovingAbsence: true
      }
    case deleteAbsenceRoutine.SUCCESS:
    case deleteAbsenceRoutine.FAILURE:
      return {
        ...state,
        isRemovingAbsence: false
      }
    case fetchEmployeeRoutine.REQUEST:
      return {
        ...state,
        isFetchingEmployee: true
      }
    case fetchEmployeeRoutine.SUCCESS:
    case fetchEmployeeRoutine.FAILURE:
      return {
        ...state,
        isFetchingEmployee: false
      }
    case fetchCurrentUserRoutine.REQUEST:
      return {
        ...state,
        isFetchingCurrentUser: true
      }
    case fetchCurrentUserRoutine.SUCCESS:
    case fetchCurrentUserRoutine.FAILURE:
      return {
        ...state,
        isFetchingCurrentUser: false
      }
    case fetchAllEmployeesRoutine.REQUEST:
      return {
        ...state,
        isFetchingAllEmployees: true
      }
    case fetchAllEmployeesRoutine.SUCCESS:
    case fetchAllEmployeesRoutine.FAILURE:
      return {
        ...state,
        isFetchingAllEmployees: false
      }
    case deleteEmployeeRoutine.REQUEST:
      return {
        ...state,
        isRemovingEmployee: true
      }
    case deleteEmployeeRoutine.SUCCESS:
    case deleteEmployeeRoutine.FAILURE:
      return {
        ...state,
        isRemovingEmployee: false
      }
    case updateEmployeeRoutine.REQUEST:
      return {
        ...state,
        isUpdatingEmployee: true
      }
    case updateEmployeeRoutine.SUCCESS:
    case updateEmployeeRoutine.FAILURE:
      return {
        ...state,
        isUpdatingEmployee: false
      }
    case updateAbsenceRoutine.REQUEST:
      return {
        ...state,
        isUpdatingAbsence: true
      }
    case updateAbsenceRoutine.SUCCESS:
    case updateAbsenceRoutine.FAILURE:
      return {
        ...state,
        isUpdatingAbsence: false
      }
    case deleteSingleDayFromAbsenceRoutine.REQUEST:
      return {
        ...state,
        isRemovingSingleDay: true
      }
    case deleteSingleDayFromAbsenceRoutine.SUCCESS:
    case deleteSingleDayFromAbsenceRoutine.FAILURE:
      return {
        ...state,
        isRemovingSingleDay: false
      }
    default:
      return state
  }
}
