import React, { useCallback } from 'react'
import {
  LogoutIcon,
  SideNavigation,
  SideNavListItem
} from 'de-suite-me-ui'
import { redirect } from 'utils/router'
import { PATHS } from 'utils/paths'
import { logoutUserRoutine } from 'features/auth/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsAdmin } from 'features/auth/ducks/selectors'
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'

const Navigation = () => {
  const dispatch = useDispatch()
  const logoutUser = useCallback(() => dispatch(logoutUserRoutine()), [dispatch])
  const redirectToPath = path => () => redirect(path)
  const isAdmin = useSelector(selectIsAdmin)

  return (
    <SideNavigation
      appName={"I'mOut"}
      bottomElements={(
        <SideNavListItem
          text='Logout'
          icon={LogoutIcon}
          onClick={logoutUser}
        />
      )}
    >
      <SideNavListItem
        text='Dashboard'
        icon={DashboardOutlinedIcon}
        onClick={redirectToPath(PATHS.dashboard)}
      />
      <SideNavListItem
        text='Calendar'
        icon={EventOutlinedIcon}
        onClick={redirectToPath(PATHS.calendar)}
      />
      <SideNavListItem
        text='User panel'
        icon={AssignmentIndOutlinedIcon}
        onClick={redirectToPath(PATHS.userPanel)}
      />
      {
        isAdmin && (
          <SideNavListItem
            text='Users'
            icon={PeopleAltOutlinedIcon}
            onClick={redirectToPath(PATHS.users)}
          />
        )
      }
    </SideNavigation>
  )
}

export default Navigation
